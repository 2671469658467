:root {
  --dropdown-border: transparent; /* Default border color */
}

.banner {
  background-color: white;
  padding: 0 5.5%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* box-shadow: 0px 4px 12px rgb(0 0 0 / 7%); */
}

.banner > div {
  /* border: 1px solid black; */
}

.banner h5,
.hero3 h5,
.hero2 h5,
.hero1 h5,
h3 {
  margin: 0;
}

.mainRightBg {
  background: #f8f8f8;
  padding-top: 40px;
  border-radius: 30px 0 0 0;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flexAlign7 {
  display: flex;
  align-items: center;
  gap: 7px;
}

.flexAlign20 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.flexAlign10 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flexAlignStart10 {
  display: flex;
  align-items: start;
  gap: 10px;
  line-height: 1rem;
}

.line {
  border: none;
  border-bottom: 1px solid rgba(122, 122, 122, 0.318);
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fw600 {
  font-weight: 600;
}

.bannerRight {
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.mainNotifications {
  position: relative;
}

.bellCon {
  padding: 6px 7px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

.bellCon:hover {
  background: rgb(0 0 0 / 5%);
}

.bellCon img {
  height: 1.3rem;
}

.bellCon span {
  position: absolute;
  top: 0;
  right: 0;
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff0004;
  border-radius: 50%;
}

.bellCon h5 {
  font-size: 7px;
  color: white;
  margin-left: 1px;
}
.notifications {
  position: absolute;
  top: 37px;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 6px 25px rgb(0 0 0 / 8%);
  border-radius: 10px;
  height: 18rem;
  width: 16rem;
  z-index: 5;
}

.notifyBanner {
  padding: 7px 15px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 3%);
  border-radius: 10px 10px 0px 0px;
}

.notifyBanner h5 {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: black;
}

.notifyBody {
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyBox {
}

.emptyBox img {
  height: 5rem;
}

.emptyBox h5 {
  color: rgb(175 175 175);
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.hero2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 5%;
  margin-top: 0;
}

.hero2 h5 {
  font-size: 13px;
  font-weight: 600;
}

.searcCon {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 4px 12px;
  margin-right: 2.5%;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.searcCon h5 {
  margin: 0;
  line-height: 1rem;
  text-align: center;
}

.searcCon input {
  height: 100%;
  border: none;
  background: none;
  outline: none;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  width: 13rem;
}

.searcCon input::placeholder {
  color: rgb(153, 153, 153);
}

.searcCon img {
  height: 0.7rem;
}

.searcCon span {
  color: #0262df;
  font-size: 12px;
}

.inviteMain {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.inviteMain input {
  height: 100%;
  border: none;
  background: none;
  outline: none;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  width: 9rem;
  padding-left: 15px;
}

.inviteMain input::placeholder {
  color: rgb(153, 153, 153);
}

.role {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.role h5 {
  font-size: 12px;
  cursor: pointer;
}

.inviteMain button {
  border: none;
  background: none;
  height: 100%;
  width: 4.5rem;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}

.inviteMain button h5 {
  color: white !important;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero3 {
  padding: 0 5%;
  border-radius: 7px;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.tableCon {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 7px 7px;
  border-radius: 7px;
}

.tableHeader {
  border-radius: 7px 7px 0 0;
  display: grid;
  grid-template-columns: 25% 25% 21% 18% 11%;
  padding: 9px 20px;
}

.tableHeader h5 {
  font-size: 13px;
  color: white;
}

.tableBody {
  position: relative;
}

.rowHeader {
  display: grid;
  padding: 9px 20px;
  grid-template-columns: 25% 25% 21% 18% 11%;
  border-bottom: 1px solid #ebebeb;
}

.rowHeader h5 {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.rowHeader > div {
  display: flex;
  align-items: center;
}

.status {
  border: 1px solid;
  padding: 2px 10px;
}

.status h5 {
  font-weight: 600 !important;
}

.pendingStatus {
  background: #ffecd6;
  border: 1px solid #ff8800;
  border-radius: 7px;
  color: #ff8800;
}

.activeStatus {
  background: #b1ffdb;
  border: 1px solid #008c4b;
  border-radius: 7px;
  color: #008c4b;
}

.removeTxt {
  color: #848484;
  cursor: pointer;
  font-weight: 600 !important;
}

.pagFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 20px;
}

.pagFooter h5 {
  font-size: 13px;
  font-weight: 700;
}

.pagFooter li button {
  font-family: "manrope", sans-serif;
  font-weight: 700;
  font-size: 13px;
  min-width: 20px;
  height: 23px;
  padding: 0 3px;
  margin: 0 1px;
}
.arrow {
  /* cursor: pointer; */
  transition-property: all;
  transition-duration: 0.3s;
}

/* Dropdown start */
.dropDown {
  position: relative;
}

.dropDownHead {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 1.9rem;
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: 600;
  color: #7a7a7a;
}

.dropDownHead2 {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 2rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
  color: #7a7a7a;
}

.dropDownBody {
  position: absolute;
  top: 40px;
  z-index: 1;
  user-select: none;
}

.filterdropDownBody {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
}

.filterdropDownBodyInner {
  height: 7rem;
  width: 15rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 10px 0;
  z-index: 2;
}

.dropDownBodyInner {
  /* height: 7rem; */
  width: 9rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 10px 0;
  z-index: 2;
}

.ddPointerCon {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1;
}

.dropDownPointer {
  box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
  background: white;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
}

.dropDownElem {
  padding: 2px 10px;
  border-left: 3px solid;
  cursor: pointer;
  border-color: transparent;
  transition-property: all;
  transition-duration: 0.3s;
}

.activeDropElem {
  border-color: var(--dropdown-border) !important;
}

.activeDropElem h5 {
  color: var(--dropdown-border);
}

.dropDownElem:hover {
  border-color: var(--dropdown-border);
}

.dropDownElem:hover h5 {
  color: var(--dropdown-border);
}

.dropDownElem h5 {
  font-weight: 600;
}

.dropDownLine {
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 5px 11px;
}

.loadingPopup {
  background-color: #ffffffa2;
  backdrop-filter: blur(1px);
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.loadingPopup h5 {
  font-size: 15px;
  font-weight: 600;
}

/* Dropdown end */

.sampleText{
  font-size: 13px;
  font-weight: 700;
  padding: 9px 20px;
}