@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

:root {
    --dropdown-border: transparent; /* Default border color */
}

.banner {
    background-color: white;
    padding: 0 5.5%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    /* box-shadow: 0px 4px 12px rgb(0 0 0 / 7%); */
}

.banner>div {
    /* border: 1px solid black; */

}

.banner h5,
.hero3 h5,
.hero2 h5,
.hero1 h5,
h3 {
    margin: 0;
}

.bannerMid {
    border: 1px solid black;
    border-radius: 3px;
    height: 2rem;
    padding: 4px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
}

.bannerMid h5 {
    font-size: 13px;
}

.bmLeft {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 6rem;
    padding: 0 7px;
}

.bmRight {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 7px;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
}

.flexAlign7 {
    display: flex;
    align-items: center;
    gap: 7px;
}

.flexAlign20 {
    display: flex;
    align-items: center;
    gap: 20px;
}

.flexAlign10 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.flexAlignStart10 {
    display: flex;
    align-items: start;
    gap: 10px;
    line-height: 1rem;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pad3 {
    padding: 10px;
}

.bannerRight {
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.mainNotifications{
    position: relative;
}

.bellCon{
    padding: 6px 7px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s;
}

.bellCon:hover{
    background: rgb(0 0 0 / 5%);
}

.bellCon img{
    height: 1.3rem;
}

.bellCon span{
    position: absolute;
    top: 0;
    right: 0;
    height: 12px;
    width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF0004;
    border-radius: 50%;
}

.bellCon h5{
    font-size: 7px;
    color: white;
    margin-left: 1px;
}

.notifications{
    position: absolute;
    top: 37px;
    right: 0px;
    background: #FFFFFF;
    box-shadow: 0px 6px 25px rgb(0 0 0 / 8%);
    border-radius: 10px;
    height: 18rem;
    width: 16rem;
    z-index: 5;
}

.notifyBanner{
    padding: 7px 15px;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 3%);
    border-radius: 10px 10px 0px 0px;
}

.notifyBanner h5{
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.notifyBody{
    height: 85%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyBox{
}

.emptyBox img{
    height: 5rem;
}

.emptyBox h5{
    color: rgb(175 175 175);
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.hero1 {
    min-height: 4rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 6%);
    border-radius: 15px;
    margin: 0px 5% 20px 5%;
    padding: 20px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainRightBg {
    background: #F8F8F8;
    padding-top: 1px;
    border-radius: 30px 0 0 0;
}

.miniLine {
    height: 4rem;
    background: rgb(0 0 0 / 6%);
    width: 2px;
}

.hero1Img {
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero1Img img {
    height: 20px;
}

.heroBox {
    display: flex;
    align-items: center;
    gap: 12px;
}

.hero2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 5%;
    margin-top: 2rem;
}

.hero2 h5 {
    font-size: 13px;
    font-weight: 600;
}

.filterTitle{
    font-family: 'Poppins', sans-serif;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.filterCon{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 9rem;
    padding: 5px 12px;
    cursor: pointer;
}

.filterIcon{
    height: 18px;
}

.filterCon h5{
    font-size: 15px;
    font-weight: 700;
}

.searcCon {
    border: 1px solid #BEBEBE;
    border-radius: 32px;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    padding: 4px 12px;
    margin-right: 2.5%;
}

.searcCon h5 {
    margin: 0;
    line-height: 1rem;
    text-align: center;
}

.searcCon input {
    height: 100%;
    border: none;
    background: none;
    outline: none;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    width: 9rem;
}

.searcCon input::placeholder {
    color: rgb(153, 153, 153);
}

.searcCon img {
    height: 0.9rem;
}

.searcCon span {
    color: #0262DF;
    font-size: 17px;
}

.topBox {
    background: #FBFBFB;
    border: 1px solid #bebebeb0;
    border-radius: 6px;
    padding: 4px 8px;
    cursor: pointer;
}

.mr10 {
    margin-right: 10px;
}

.hero3 {
    padding: 0 5%;
    border-radius: 7px;
    margin-bottom: 1rem;
    min-height: 70vh;
}

.tableCon {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 7px 7px;
    border-radius: 7px;
}

.tableHeader {
    border-radius: 7px 7px 0 0;
    display: grid;
    grid-template-columns: 10% 10% 21% 20% 15% 16% 8%;
    padding: 9px 20px;
}

.tableHeader h5 {
    font-size: 13px;
    color: white;
}

.tableBody{
    position: relative;
}

.rowHeader {
    display: grid;
    padding: 9px 20px;
    grid-template-columns: 10% 10% 21% 20% 15% 16% 8%;
    border-bottom: 1px solid #ebebeb;
    min-height: 3rem;
}

.rowHeader h5 {
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.rowHeader > div{
    display: flex;
    align-items: center;
}

.rowExpanded {
    padding: 9px 20px;
    background: #F8F8F8;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    max-height: 25rem;
    overflow-y: auto;
}

.expandedMiniCon {
    background: white;
    border-radius: 7px;
}

.expandedMiniConPadd {
    padding: 20px 20px 25px 20px;

}

.miniTitle {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    line-height: 1rem;
}

.imgIcon{
    width: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    border: 1px solid rgb(0 0 0 / 6%);
}

.iconCon{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.para {
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    /* margin-top: 10px !important; */
}

.para2 {
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    margin-bottom: 20px !important;
    margin-top: 3px !important;
    /* margin-left: 3px !important; */
    color: black;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.miniColoredText {
    font-size: 13px;
    font-weight: 600;
}

.m10 {
    margin: 10px 0;
}

.con3 {
    margin: 13px 0 16px 0;
}

.con3 h5 {
    font-size: 14px;
    font-weight: 700;
}

.con3Footer h5 {
    font-size: 14px;
    font-weight: 700;
    color: white;
}

.con3Footer {
    padding: 15px 20px;
    border-radius: 0 0 7px 7px;
    margin: 0 !important;
}

.icon3 {
    height: 15px;
}

.pagFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 20px;
}

.pagFooter h5 {
    font-size: 13px;
    font-weight: 700;
}

.pagFooter li button {
    font-family: 'manrope', sans-serif;
    font-weight: 700;
    font-size: 13px;
    min-width: 20px;
    height: 23px;
    padding: 0 3px;
    margin: 0 1px;
}

/* .pagination {
    background: #FBFBFD;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
} */

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    transition-property: all;
    transition-duration: 0.3s;
}

.fw600 {
    font-weight: 600;
}

.container {
    padding: 15px 4%;
    padding-bottom: 3rem;
    margin-left: 2%;
    /* margin-top: -1.8rem; */
    /* border: 2px solid black; */
}


.accordion {
    background-color: transparent !important;
    margin-bottom: 0px;
    box-shadow: none !important;
}

.accordionHeader {
    /* border: 1px solid black; */
    padding: 0 !important;
}

.accordionHeader>div {
    margin: 0 !important;
}

.accordionBody {
    /* border: 1px solid red; */
    padding: 0 0 10px 0 !important;
    padding-bottom: 10px !important;
}

.accordionHeader {
    min-height: 10px !important;
}

.select {
    /* border: 1px solid rgba(122, 122, 122, 0.5); */
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    color: black;
    padding: 7px 15px;
    box-sizing: border-box;
    outline: none;
}

.select::placeholder {
    color: grey;
}

.dropDown{
    position: relative;
}

.dropDownHead{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 1.9rem;
    width: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    user-select: none
}

.dropDownHead{
    font-size: 13px;
    font-weight: 600;
    color: #7A7A7A;
}

.dropDownBody{
    position: absolute;
    top: 40px;
    z-index: 1;
    user-select: none;
}

.filterdropDownBody{
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
}

.filterdropDownBodyInner{
    height: 7rem;
    width: 15rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 10px 0;
    z-index: 2;
}

.dropDownBodyInner{
    /* height: 7rem; */
    width: 9rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 10px 0;
    z-index: 2;
}

.ddPointerCon{
    position: absolute;
    top: -5px;
    right: 15px;
    z-index: 1;
}

.dropDownPointer{
    box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
    background: white;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
}

.dropDownElem{
    padding: 2px 10px;
    border-left: 3px solid;
    cursor: pointer;
    border-color: transparent;
    transition-property: all;
    transition-duration: 0.3s;
}

.activeDropElem{
    border-color: var(--dropdown-border) !important;
}

.activeDropElem h5{
    color: var(--dropdown-border);
}

.dropDownElem:hover {
    border-color: var(--dropdown-border);
}

.dropDownElem:hover h5{
    color: var(--dropdown-border);
}

.dropDownElem h5{
    font-weight: 600;
}

.dropDownLine{
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 5px 11px;
}

.loadingPopup {
    background-color: #ffffffa2;
    backdrop-filter: blur(1px);
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.loadingPopup h5{
    font-size: 15px;
    font-weight: 600;
}

@media (min-width:1600px) {
    .rowExpanded {
        gap: 5%;
    }
}

.scoresCon h5{
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
}