.faqContainer {
    margin-top: 2rem;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 2rem;
}

.faqContainer::before {
    background-color: none !important;
}

.faqContainer h1 {
    font-size: 45px;
    font-family: sans-serif, manrope;
    color: black;
    margin-top: 0;
}

.qnaCon {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.accordion {
    border: 1px solid #0262DF !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    margin: 0.5rem 0 !important;
    padding: 1.3rem 0px !important;
    width: 100% !important;
}

@media (max-width: 1200px) {
    .faqContainer h1 {
        font-size: 40px;
    }
}

@media (max-width:1000px) {
    .faqContainer h1 {
        font-size: 35px;
        margin: 1rem;
    }
}

@media (max-width: 800px) {
    .faqContainer h1 {
        font-size: 28px;
        margin: 0.5rem;
    }

    .accordion {
        padding: 1rem 0px !important;
    }
}

@media (max-width: 600px) {
    .faqContainer h1 {
        font-size: 22px;
    }
}
@media (max-width: 480px) {
    .faqContainer h1 {
        font-size: 18px;
    }
    .qnaCon p{
        text-align: start;
        font-size: 13px;
    }
}