.popup {
  background-color: #00000057;
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.card {
  background: white;
  padding: 1.7rem 0rem 2rem 0rem;
  border-radius: 8px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0px 4px 12px #0362e038; */
  width: 40rem;
  max-height: 90%;
  overflow-y: auto;
  text-align: left;
  border: none;
  position: relative;
  /* padding-right: 5px;
  padding-top: 2px; */
}

.card::-webkit-scrollbar {
    width: 10px;
}

.card::-webkit-scrollbar-track {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.card::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.card::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d49 90%);
    border-radius: 7px;
}

.close {
  position: absolute;
  right: 23px;
  top: 16px;
  cursor: pointer;
}

.close svg {
  height: 0.5rem;
}

.title,
.subtitle,
.text {
  font-family: "Manrope", sans-serif;
}

.title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 1.5rem !important;
}

.subtitle {
  font-size: 15px;
  font-weight: 600;
}

.text {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 1.5rem !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line {
  border: none;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 1rem 0;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px !important;
}

.logoImg {
  height: 1.5rem;
}

.downloadBtn {
  background: none;
  border: none;
  padding: 5px 12px;
  border-radius: 7px;
  margin-top: 5px;
  cursor: pointer;
}

.downloadBtn h5 {
  color: white;
  font-size: 13px;
}

.header {
  padding: 0 3rem;
}

.body{
    padding: 0 3rem;
    padding-top: 1.5rem;
}
