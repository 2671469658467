:root {
  --black: black;
  --blue: #0262df;
}

/* DropDown Start */
.dropDown {
  position: relative;
  margin-bottom: 15px;
}

.dropDownHead {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 1.9rem;
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
}

.dropDownHead {
  font-size: 13px;
  font-weight: 600;
  color: #7a7a7a;
}

.dropDownBody {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
  user-select: none;
}

.filterdropDownBody {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
}

.filterdropDownBodyInner {
  height: 7rem;
  width: 15rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 10px 0;
  z-index: 2;
}

.dropDownBodyInner {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 10px;
  z-index: 2;
}

.dropDownBodyInner > div {
  height: 10.5rem;
  width: 13rem;
  overflow-y: scroll;
}

.dropDownBodyInner > div::-webkit-scrollbar {
  width: 10px;
}

.dropDownBodyInner > div::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.015) -122%,
    #f3f3f3 95%
  );
  border-radius: 7px;
}

.dropDownBodyInner > div::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d23 90%
  );
  border-radius: 7px;
}

.dropDownBodyInner > div::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d49 90%
  );
  border-radius: 7px;
}

.ddPointerCon {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1;
}

.dropDownPointer {
  box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
  background: white;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
}

.dropDownElem {
  padding: 0 7px;
  /* border: 1px solid; */
  /* border-left: 3px solid; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-color: transparent;
  transition-property: all;
  transition-duration: 0.3s;
}

.dropDownElem h5 {
  cursor: pointer;
}

.activeDropElem {
  border-color: var(--dropdown-border) !important;
}

.activeDropElem h5 {
  /* color: var(--dropdown-border); */
}

.dropDownElem:hover {
  border-color: var(--dropdown-border);
}

.dropDownElem:hover h5 {
  /* color: var(--dropdown-border); */
}

.dropDownElem h5 {
  font-weight: 600;
}

.dropDownLine {
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 5px 11px;
}
/* DropDown End */

.SelectinputField {
  background: white;
  padding: 0 1rem;
  font-family: Poppins, sans-serif;
  border-radius: 8px;
  height: 44px;
  color: #242436;
  border: 1px solid rgba(230, 232, 236, 1);
  outline: none;
  font-family: "Manrope", sans-serif;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  color: #242436;
  font-size: 13px;
  font-weight: 500;
}

.SelectinputField:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  font-family: "Manrope", sans-serif;
  font-size: 13px;
}

.SelectinputField p{
  color: #242436;
}

.searcCon {
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 4px 7px;
  margin-right: 2.5%;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.searcCon h5 {
  margin: 0;
  line-height: 1rem;
  text-align: center;
}

.searcCon input {
  height: 100%;
  border: none;
  background: none;
  outline: none;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  width: 9rem;
}

.searcCon input::placeholder {
  color: rgb(153, 153, 153);
}

.searcCon img {
  height: 0.7rem;
}

.searcCon span {
  color: #0262df;
  font-size: 12px;
}
