@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

.banner {
    background-color: white;
    padding: 17px 5%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    /* box-shadow: 0px 4px 12px rgb(0 0 0 / 7%); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerMid {
    border: 1px solid black;
    border-radius: 3px;
    height: 2.5rem;
    padding: 4px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
}

.bannerMid h5 {
    font-size: 13px !important;
    font-weight: 600 !important;
}

.bmLeft {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 6rem;
    padding: 0 7px;
}

.bmRight {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 7px;
}


.fw600 {
    font-weight: 600;
}

.container {
    background: #F8F8F8;
    border-radius: 30px 0 0 0;
    padding: 15px 4%;
    padding-bottom: 3rem;
    padding-left: 2%;
    /* margin-top: -1.8rem; */
    /* border: 2px solid black; */
}

.iconCon {
    height: 18px;
}

.finalCard {
    border: 2px solid green; /* change the border color */
}

.finalTick {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
}

.searchSection {
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    height: 3.6rem;
    margin-top: 0.5rem;
}

.tooltipCon {
    /* position: relative; */
    cursor: default;
    padding: 8px 27px;
}

.tooltipCon:hover .tooltip {
    display: block !important;
}

.tooltip {
    /* position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center; */
    display: none;
}

.tooltip h5 {
    /* background: rgb(218 234 255); */
    /* border-radius: 16px; */
    font-size: 12px;
    position: relative;
    margin: 0;
    text-align: center;
    color: #8d8d8d;
    font-weight: 600;
}

.tooltipArrow {
    position: absolute;
    top: -4px;
    height: 14px;
    width: 14px;
    background: rgb(218 234 255);
    transform: rotate(45deg);
}

.searcCon {
    border: 1px solid #BEBEBE;
    border-radius: 32px;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    padding: 4px 16px;
    margin-right: 2.5%;
}

.remainingTextCon {
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    /* height: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* padding: 4px 27px; */
    margin-right: 2.5%;
    height: fit-content;
}

.sText {
    height: 100%;
    font-size: 14px;
    border-radius: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 3px;
}

.sText span {
    font-size: 16px;
    color: #0262DF;
}

.searcCon h5 {
    margin: 0;
    line-height: 1rem;
    text-align: center;
}

.searcCon input {
    height: 100%;
    border: none;
    background: none;
    outline: none;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    width: 11rem;
}

.searcCon input::placeholder {
    color: rgb(153, 153, 153);
}

.searcCon img {
    height: 1.1rem;
}

.searcCon span {
    color: #0262DF;
    font-size: 17px;
}

.text2 {
    max-width: 1500px;
    color: #0262DF;
    text-decoration: underline;
    margin: 0;
    margin-bottom: 1.2rem;
    padding-left: 27px;
    cursor: pointer;
}

.main {
    max-width: 1500px;
    margin: 0 auto;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.cards_item {
    display: flex;
    padding-right: 2.5%;
    margin-bottom: 1.5rem;
}

li::marker {
    display: none;
}

/* @media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}
@media (min-width: 56rem) {
  .cards_item {
    width: 22.5%;
  }
} */
.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 9%) 0px 3px 7px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* height: fit-content; */
    width: 100%;
}

.card_image {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 11rem;
    position: relative;
}

.card_image img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    /* color: transparent; */
}


.loadingCon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: end;
}

.loadingCon h5 {
    font-size: 13px;
    margin: 10px;
    color: #959595;
}

.card_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    margin-bottom: 3px;
    height: 2rem;
}

.card_content span {
    font-weight: 700;
    font-family: "manrope", sans-serif;
    font-size: 14px;
    position: relative;
    top: 2px;
}

.card_content a {
    margin-left: auto;
    cursor: pointer;
    text-decoration: none;
}

.card_content img {
    width: 15px;
    height: 14px;
    cursor: pointer;
}

.card_title {
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin: 0px;
}

.card_text {
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    font-weight: 400;
}

.deleteBtn{
    padding: 3px 7px;
    outline: none;
    background: rgb(255, 207, 207);
    border: none;
    border-radius: 7px;
    cursor: pointer;
}

.deleteBtn h5{
    color: rgb(212, 0, 0);
    font-size: 12px;
}

.emptyUpload {
    /* border: 1px solid black; */
    /* min-height: 4rem; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-right: 2.5%;
    /* padding-top: 2rem; */
}

/* .banner h5 {
    font-weight: 600;
    font-size: 15px;
    margin: 0;
}

.banner span {
    color: #0262DF;
    cursor: pointer;
}

.banner span:hover {
    text-decoration: underline;
} */

.emptyUpload img {
    height: 26rem;
}

.emptyUploadTitle {
    color: black;
    font-size: 30px;
    margin-bottom: 1rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
}

.emptyUploadDesc {
    text-align: center;
    font-size: 20px;
    color: #5E5E5E;
    margin: 0;
    margin-bottom: 2rem;
}

.emptyUploadButtons {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.emptyUploadButton1 {
    border: 1px solid #0262DF;
    background: none;
    padding: 12px 30px;
    border-radius: 10px;
    cursor: pointer;
    width: 15rem;
}

.emptyUploadButton2 {
    border: 1px solid transparent;
    padding: 12px 30px;
    border-radius: 10px;
    background: linear-gradient(90deg, #006FFF 0%, #0262DF 100%);
    cursor: pointer;
    width: 15rem;
}

.emptyUploadButton1 h5 {
    color: #0262DF;
}

.emptyUploadButton2 h5 {
    color: white;
}

.emptyUploadButton1 h5,
.emptyUploadButton2 h5 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
}

.flex_align2 {
    display: flex;
    align-items: end;
}

.flex_align2 span h5 {
    font-weight: 700;
    font-family: "manrope", sans-serif;
    font-size: 20px;
    position: relative;
    top: 2px;
    margin: 0;
}

.emptyDeck{
    min-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyDeck p{
    color: #9c9c9c;
    font-size: 15px;
    font-family: 'Manrope', sans-serif;
}

.uploadIconCon {
    margin-left: auto;
    cursor: pointer;
    text-decoration: none;
}

.uploadIconCon img {
    width: 15px;
    height: 14px;
    cursor: pointer;
}

/* .reactPdf{
    border: 1px solid black;
} */
.reactPdf>div {
    /* display: none; */
}

.reactPdf canvas {
    height: auto !important;
}


.ConfirmUploadPopup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.confirmCard {
    background: white;
    padding: 1.5rem 0;
    border-radius: 8px;
    /* max-width: 325px; */
    width: 22rem;
    text-align: left;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
}

.close {
    position: absolute;
    right: 23px;
    top: 16px;
    cursor: pointer;
}

.close img {
    height: 0.8rem;
    width: 0.8rem;
}

.confirmCard>h3 {
    font-size: 23px;
    font-weight: 800;
    text-align: center;
    margin: 0;
    margin-bottom: 5px;
}

.confirmCard>h5 {
    color: #676767;
    text-align: center;
    margin: 0;
    font-weight: 400;
}

.confirmImg {
    width: 100%;
    height: 12rem;
    position: relative;
}

.confirmImg img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
}

.blueBtn {
    background-color: #0262DF;
    border: none;
    margin: 0 auto;
    padding: 9px 31px;
    border-radius: 9px;
    margin-top: 1.5rem;
    cursor: pointer;
}

.blueBtn h3 {
    margin: 0;
    color: white;
}

.loadingDots:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow:
            .25em 0 0 rgba(0, 0, 0, 0),
            .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: black;
        text-shadow:
            .25em 0 0 rgba(0, 0, 0, 0),
            .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow:
            .25em 0 0 rgb(0, 0, 0),
            .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow:
            .25em 0 0 black,
            .5em 0 0 black;
    }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dotsCon {
    width: 2.2rem;
    display: flex;
    justify-content: center;
}

.flexEnd {
    display: flex;
    align-items: end;
}

.dotFlashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    color: black;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dotFlashing::before,
.dotFlashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dotFlashing::before {
    left: -9px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dotFlashing::after {
    left: 9px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: black;
    }

    50%,
    100% {
        background-color: #dfdfdf;
    }
}


/* min-width: 640px */
@media (min-width: 40rem) {
    .cards_item {
        width: 50%;
    }
}

/* min-width: 896px */
@media (min-width: 56rem) {
    .cards_item {
        width: 25%;
    }
}

@media (max-width: 1800px) {
    .card_image {
        height: 10rem;
    }
}

@media (max-width: 1700px) {
    .card_image {
        height: 9rem;
    }
}

@media (max-width: 1600px) {
    .card_image {
        height: 8rem;
    }

    .banner {
        padding: 15px 0;
    }
}

@media (max-width: 1600px) {
    .card_image {
        height: 8rem;
    }

    .emptyUpload img {
        height: 22rem;
    }

    .emptyUploadTitle {
        font-size: 25px;
        ;
    }

    .emptyUploadDesc {
        font-size: 18px;
    }

    .emptyUploadButton1 {
        padding: 9px 30px;
        width: 13rem;
    }

    .emptyUploadButton2 {
        padding: 9px 30px;
        width: 13rem;
    }

    .emptyUploadButton1 h5,
    .emptyUploadButton2 h5 {
        font-size: 15px;
    }
}

@media (max-width: 1500px) {
    .container {
        padding: 15px 3.5%;
        padding-left: 2.25%;
    }
}

@media (max-width: 1400px) {
    .card_image {
        height: 7rem;
    }
}

@media (max-width: 1300px) {
    .card_image {
        height: 6rem;
    }
}

@media (max-width: 1200px) {
    .card_image {
        height: 8rem;
    }

    .container {
        padding: 12px 3%;
    }

    .cards_item {
        width: 33.33%;
        padding-right: 3%;
    }

    .searcCon {
        margin-right: 3%;
    }
}

@media (max-width: 1100px) {
    .card_image {
        height: 7rem;
    }
}

@media (max-width: 1025px) {
    .searcCon {
        padding: 0px 10px;
        gap: 3px;
    }

    .searcCon h5 {
        font-size: 11px;
        font-weight: 600;
    }

    .searcCon span {
        font-size: 11px;
    }

    .text2 {
        font-size: 11px;
        font-weight: 600;
        padding-left: 11px;
    }

    .searcCon input {
        font-size: 12px;
        width: 5rem;
    }

    .searcCon svg {
        height: 15px;
        width: 15px;
    }

    .container {
        padding: 12px 3%;
        padding-left: 2.25%;
    }

    .card_image {
        height: 7rem;
    }

    .emptyUpload img {
        height: 17rem;
    }

    .emptyUploadTitle {
        font-size: 22px;
    }

    .emptyUploadDesc {
        font-size: 15px;
    }

    .emptyUploadButton1 {
        padding: 7px 25px;
    }

    .emptyUploadButton2 {
        padding: 7px 25px;
    }

    .emptyUploadButton1 h5,
    .emptyUploadButton2 h5 {
        font-size: 13px;
    }
}

@media (max-width: 900px) {
    .cards_item {
        width: 50%;
        padding-right: 3%;
    }

    .card_image {
        height: 8rem;
    }
}

@media (max-width: 800px) {
    .card_image {
        height: 7rem;
    }
    .searchSection{
        height: auto;
    }
}

@media (max-width: 700px) {
    .card_image {
        height: 6rem;
    }
}

@media (max-width: 630px) {
    .card_image {
        height: 8rem;
    }

    .searchSection {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 600px) {
    .searchSection {
        flex-direction: row;
        gap: 2px;
    }

    .container {
        margin-top: 0;
    }

    .searchSection {
        margin-bottom: 1.3rem;
    }
}

@media (max-width: 507px) {
    .searchSection {
        flex-direction: column;
        gap: 7px;
    }
}

@media (max-width: 480px) {

    .container {
        padding: 12px 20%;
    }

    .cards_item {
        width: 100%;
        padding-right: 0;
    }

    .searcCon {
        margin-right: 0;
    }

    .searcCon {
        height: 2rem;
        gap: 5px;
        padding: 0px 9px;
    }

    .searcCon input {
        font-size: 13px;
        width: 7rem;
    }
}