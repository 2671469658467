* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "sans-serif", "manrope" !important;
  background: #fbfbfb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h5 {
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.m0 {
  margin: 0;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.red {
  color: #ff6c6c;
}

.mt10 {
  margin-top: 10px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexAlign7{
  display: flex;
  align-items: center;
  gap: 7px;
}
.flexAlign10{
  display: flex;
  align-items: center;
  gap: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.react-multiple-carousel__arrow::before {
  font-size: 15px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: black !important;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: auto;
  position: relative;
  border: "1px solid black" !important;
}

.react-multi-carousel-dot-list {
  bottom: 16px;
}

.react-multi-carousel-dot button {
  height: 7px !important;
  width: 7px !important;
  border: "1px solid black" !important;
}

.react-multiple-carousel__arrow {
  background: white !important;
  /* border:1px solid black !important; */
  box-shadow: 0 1.2px 1.92251px rgba(0, 0, 0, 0.02),
    0 2px 3.68135px rgba(0, 0, 0, 0.04), 0 1px 5px rgba(0, 0, 0, 0.05);
  color: black !important;
  min-width: 35px !important;
  min-height: 35px !important;
}

.react-multiple-carousel__arrow--left::before {
  margin-left: -2px !important;
}

.react-multiple-carousel__arrow--left::before {
  margin-right: 2px !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(2.6% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(2.6% + 1px) !important;
}

/* .react-multiple-carousel__arrow--left {
  left: calc(4.6% + 1px);
} */

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: "0px solid transparent" !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.css-1nvcaqg-MuiFormControl-root-MuiTextField-root {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 2% !important;
}

@media (max-width: 450px) {
  .react-multiple-carousel__arrow {
    min-width: 25px !important;
    min-height: 25px !important;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 12px !important;
  }
}

.css-19midj6 {
  padding: 16px 0 !important;
}
