:root {
  --black: black;
  --blue: #0262df;
}

.popup {
  background-color: #00000057;
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
}

.card {
  background: white;
  border-radius: 15px;
  width: fit-content;
  text-align: left;
  border: none;
  position: relative;
  width: 35rem;
  height: 82.2vh;
}

.bgPopup {
  height: 8rem;
  padding: 1rem 1.5rem;
  background: #0262df;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 15px 15px 0 0;
}

.curveCon {
  position: relative;
  bottom: -1px;
  height: 2.5rem;
  width: 100%;
  background: white;
  border-radius: 50% 50% 0 0;
}

.card form {
  position: relative;
  z-index: 2;
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  z-index: 2;
  background: white;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close svg {
  height: 10px;
}

.cardCon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 1.5rem;
  border-radius: 8px;
}

.cardCon2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  height: 27rem;
  overflow: auto;
}

.cardCon h5 {
  margin: 0;
}

.cardCon > button > h5 {
  color: white;
  font-size: 15px;
}

.cardCon a {
  text-decoration: none;
}

.title {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: white;
}

.title1 {
  font-size: 13px;
  margin-left: 2px !important;
  font-weight: 600 !important;
}

.statusText {
  font-size: 12px;
  color: #9e9e9e;
}

.text4 {
  font-size: 13px;
  color: #525252;
}

.bigTitle {
  font-size: 14px;
  font-weight: 700 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.fw13 {
  font-size: 13px !important;
}

.text2 {
  font-size: 15px;
  text-align: center;
}

.text3 {
  font-size: 13px;
  font-weight: 600;
  color: #9e9e9e;
}

.input {
  border: 1px solid rgba(122, 122, 122, 0.5);
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: black;
  padding: 7px 15px;
  box-sizing: border-box;
  margin: 0.8em 0;
  outline: none;
}

.input::placeholder {
  color: grey;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.flexAlign2 {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navBtns {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.navBtns h5 {
  color: white;
}

.navBtns > button {
  transition-property: all;
  transition-duration: 0.3s;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flexColoumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.btn1,
.btn2 {
  background: none;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 7px 15px;
  cursor: pointer;
  width: 100%;
}

.btn1 h5,
.btn2 h5 {
  margin: 0;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.m1r {
  margin: 1rem 0 !important;
}

.cardMiniHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cardScroll {
  height: 17.3rem;
  padding-right: 7px;
  width: 100%;
  overflow-y: auto;
  margin: 1rem 0;
  /* border: 1px solid; */
}

.cardScroll2 {
  height: 25rem;
  padding-right: 7px;
  width: 100%;
  overflow-y: auto;
  margin: 1rem 0;
  margin-bottom: 2.5rem;
  /* border: 1px solid; */
}

.cardScroll::-webkit-scrollbar,
.cardScroll2::-webkit-scrollbar {
  width: 10px;
}

.cardScroll::-webkit-scrollbar-track,
.cardScroll2::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.015) -122%,
    #f3f3f3 95%
  );
  border-radius: 7px;
}

.cardScroll::-webkit-scrollbar-thumb,
.cardScroll2::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d23 90%
  );
  border-radius: 7px;
}

.cardScroll::-webkit-scrollbar-thumb:hover,
.cardScroll2::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d49 90%
  );
  border-radius: 7px;
}

.cardBottom {
}

.normalBtn {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  height: 2rem;
  padding: 0 2.5rem;
  cursor: pointer;
}

.normalBtn h5 {
  color: #7a7a7a;
  font-size: 13px;
  font-weight: 600;
}

.cardScrollElem {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
  padding: 10px 15px;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.cardScrollElem2 {
  border-bottom: 1px solid #e7e7e7;
  padding: 0 0 10px 0;
  margin-bottom: 7px;
}

.flexEnd {
  display: flex;
  align-items: center;
  justify-content: end;
}

.mb10 {
  margin-bottom: 10px !important;
}
.mt10 {
  margin-top: 10px !important;
}

.commentText {
  overflow: hidden;
}

.seeMoreText {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  margin-right: 20px !important;
}

.cardScrollElemPending {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-top: 4px solid red;
  border-radius: 7px;
  padding: 10px 15px;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.pendingText {
  font-size: 12px;
  color: red;
}

.scoreTxt {
  font-size: 18px;
  font-weight: 700;
}

.reminderBtn {
  background: none;
  border: none;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.reminderBtn h5 {
  font-size: 12px;
  font-weight: 600;
}

/* Dropdown start */
.dropDown {
  position: relative;
  margin-bottom: 7px;
}

.dropDownHead {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  height: 1.9rem;
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: 600;
  color: #7a7a7a;
}

.dropDownHead2 {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 2rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
  color: #7a7a7a;
}

.dropDownBody {
  position: absolute;
  top: 40px;
  z-index: 1;
  user-select: none;
}

.filterdropDownBody {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
}

.filterdropDownBodyInner {
  height: 7rem;
  width: 15rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 10px 0;
  z-index: 2;
}

.dropDownBodyInner {
  /* height: 7rem; */
  width: 9rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 10px 0;
  z-index: 2;
}

.ddPointerCon {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1;
}

.dropDownPointer {
  box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
  background: white;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
}

.dropDownElem {
  padding: 2px 10px;
  border-left: 3px solid;
  cursor: pointer;
  border-color: transparent;
  transition-property: all;
  transition-duration: 0.3s;
}

.activeDropElem {
  border-color: var(--dropdown-border) !important;
}

.activeDropElem h5 {
  color: var(--dropdown-border);
}

.dropDownElem:hover {
  border-color: var(--dropdown-border);
}

.dropDownElem:hover h5 {
  color: var(--dropdown-border);
}

.dropDownElem h5 {
  font-weight: 600;
}

.dropDownLine {
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 5px 11px;
}

.loadingPopup {
  background-color: #ffffffa2;
  backdrop-filter: blur(1px);
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.loadingPopup h5 {
  font-size: 15px;
  font-weight: 600;
}

/* Dropdown end */

.addComment {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 4rem;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px -3px 14px rgb(0 0 0 / 7%);
  border-radius: 0 0 15px 15px;
}

.addComment textarea {
  width: 80%;
  border: none;
  height: 100%;
  outline: none;
  resize: none;
  overflow: hidden;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: black;
}

.addComment textarea::placeholder {
  color: #989898;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}
