:root {
    --black: black;
    --blue: #0262DF;
}

.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: white;
    padding: 1.5rem;
    padding-top: 6rem;
    border-radius: 15px;
    width: 28rem;
    text-align: left;
    border: none;
    position: relative;
}

.bgPopup{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9rem;
    background: #0262DF;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-radius: 15px 15px 0 0;
}

.curveCon{
    position: relative;
    bottom: -1px;
    height: 2.5rem;
    width: 100%;
    background: white;
    border-radius: 50% 50% 0 0;
}

.card form{
    position: relative;
    z-index: 2;
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    z-index: 2;
    background: white;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.close svg{
    height: 10px;
}

.cardCon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 20px 25px;
    border-radius: 8px;
    min-height: 14rem;
    padding-bottom: 10px;
    position: relative;
    z-index: 5;
}

.cardCon h5 {
    margin: 0;
}

.cardCon button h5 {
    color: white;
    font-size: 15px;
}

.cardCon a {
    text-decoration: none;
}

.confirmBtn {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 5px 13px;
    height: 35px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.confirmBtn h5 {
    color: white;
    font-size: 13px;
}

.title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 10px;
}

.text1 {
    font-size: 16px;
    margin: 15px 0;
}

.text2 {
    font-size: 15px;
    text-align: center;
}

.text3 {
    font-size: 14px;
    color: var(--blue);
}

.input {
    border: 1px solid rgba(122, 122, 122, 0.5);
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    color: black;
    padding: 7px 15px;
    box-sizing: border-box;
    margin: 0.8em 0;
    outline: none;
}

.input::placeholder {
    color: grey;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}


.btn1,
.btn2 {
    background: none;
    outline: none;
    border: none;
    border-radius: 7px;
    padding: 7px 15px;
    cursor: pointer;
    width: 100%;
}

.btn1 h5,
.btn2 h5 {
    margin: 0;
    font-size: 14px !important;
}

.m1r{
    margin: 1rem 0 !important;
}

.bannerMid {
    border: 1px solid black;
    border-radius: 3px;
    height: 2.5rem;
    padding: 4px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
}

.bannerMid h5 {
    font-size: 13px;
}

.bmLeft {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 6rem;
    padding: 0 7px;
}

.bmRight {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 7px;
}

.miniCon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 18px;
    width: 100%;
}


.btn1,
.btn2 {
    background: none;
    outline: none;
    border: none;
    border-radius: 7px;
    padding: 7px 15px;
    cursor: pointer;
    width: 100%;
}

.btn1 h5,
.btn2 h5 {
    margin: 0;
    font-size: 14px !important;
}

.lightText{
    color: #676767;
}


/* DropDown Start */
.dropDown{
    position: relative;
}

.dropDownHead{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 1.9rem;
    width: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    user-select: none
}

.dropDownHead{
    font-size: 13px;
    font-weight: 600;
    color: #7A7A7A;
}

.dropDownBody{
    position: absolute;
    top: 40px;
    right: -5px;
    z-index: 1;
    user-select: none;
}

.filterdropDownBody{
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
}

.filterdropDownBodyInner{
    height: 7rem;
    width: 15rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 10px 0;
    z-index: 2;
}

.dropDownBodyInner{
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
}

.dropDownBodyInner > div{
    height: 10.5rem;
    width: 13rem;
    overflow-y: scroll;
}

.dropDownBodyInner > div::-webkit-scrollbar {
    width: 10px;
}

.dropDownBodyInner > div::-webkit-scrollbar-track {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.dropDownBodyInner > div::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.dropDownBodyInner > div::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d49 90%);
    border-radius: 7px;
}

.ddPointerCon{
    position: absolute;
    top: -5px;
    right: 15px;
    z-index: 1;
}

.dropDownPointer{
    box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
    background: white;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
}

.dropDownElem{
    padding: 0 7px;
    /* border: 1px solid; */
    /* border-left: 3px solid; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-color: transparent;
    transition-property: all;
    transition-duration: 0.3s;
}

.dropDownElem h5{
    cursor: pointer;
}

.activeDropElem{
    border-color: var(--dropdown-border) !important;
}

.activeDropElem h5{
    /* color: var(--dropdown-border); */
}

.dropDownElem:hover {
    border-color: var(--dropdown-border);
}

.dropDownElem:hover h5{
    /* color: var(--dropdown-border); */
}

.dropDownElem h5{
    font-weight: 600;
}

.dropDownLine{
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 5px 11px;
}
/* DropDown End */

.SelectionTitle{
    font-size: 15px;
    font-weight: 600;
    color: #676767;
}

.selectionMain{
    width: 100%;
}

.selectionCon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}