.container {
    padding: 0;
    /* margin-top: -40px; */
    /* padding-top: 60px; */
    position: relative;
}

.innerCon {
    /* border: 2px solid blueviolet; */
    /* max-width: 1300px; */
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 60% 20%;
}

/* .leftCon {
    border: 1px solid black;
} */

.skeletonBox {
    padding: 6px 7px;
    height: 3rem;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    overflow: hidden;
    margin-left: 2px;
    margin-top: 2px;
}

.listCon {
    background: #FBFBFD;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    /* width: 100%; */
    height: 100%;
    padding: 1.5rem 8%;
}

.listBox {
    padding: 6px 7px;
    /* height: 3rem; */
    display: flex;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    overflow: hidden;
    margin-left: 2px;
}

.boxImgCon {
    width: 5rem;
    height: 3rem;
    position: relative;
    box-shadow: 0 0 4px -1px #6e6e6e;
    border-radius: 7px;
}

.skTxt {
    border-radius: 7px;
    background-color: #e8e8e8;
    width: 50%;
    height: 1rem;
}



.boxImgCon img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    border-radius: 7px;
}

.listBox h5 {
    margin: 0;
    font-weight: 600;
}

.iconCon{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.con1 {
    padding: 1rem 2.5rem;
    padding-top: 0;
    margin-top: 1.5rem;
}

.scroll {
    max-height: 90vh;
    overflow-y: auto;
    padding-right: 10px;
    padding-top: 2px;
}

.scroll::-webkit-scrollbar {
    width: 10px;
}

.scroll::-webkit-scrollbar-track {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d12 90%);
    border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.loadingCon {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin-left: 80px;
}

.pageCon {
    margin: auto;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    padding: 1rem;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.boxesCon {
    margin-top: 0;
}

.swiperOuter {
    background-color: #FBFBFD;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
}

.swiperCon {
    position: relative;
}

.loadingDocTxt {
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
    color: #a5a5a5;
}

.mySwiper {
    border-radius: 10px;
    background-color: #FBFBFD;
}

.swiperImg {
    border-radius: 10px;
    width: 100%;
}

.feedbackCon {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FBFBFD;
    border-radius: 10px;
    padding: 1.3rem 1rem;
    padding-top: 1.1rem;
    margin-top: 20px;
}

.feedbackCon>div {
    display: flex;
    align-items: center;
    margin-bottom: .75rem;
}

.feedbackCon h3 {
    font-family: "Manrope", sans-serif;
    color: black;
    margin: 0;
    margin-left: 1rem;
}

.feedbackCon img {
    width: 20px;
    height: 20px;
}

.feedbackCon p {
    font-size: 15px;
    font-family: "Manrope", sans-serif;
    color: black;
    margin: 0;
    height: 4.2rem;
    overflow: auto;
}

.feedbackCon p::-webkit-scrollbar,
.suggestCon p::-webkit-scrollbar
{
    width: 10px;
}

.feedbackCon p::-webkit-scrollbar-track,
.suggestCon p::-webkit-scrollbar-track
{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.feedbackCon p::-webkit-scrollbar-thumb,
.suggestCon p::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d12 90%);
    border-radius: 7px;
}

.feedbackCon p::-webkit-scrollbar-thumb:hover,
.suggestCon p::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.suggestCon {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 1.1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FBFBFD;
    border-radius: 10px;
    padding: 1.3rem 1rem;
    padding-top: 1.1rem;
    margin-top: 20px;
}

.suggestCon>div {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}

.suggestCon h3 {
    font-family: "Manrope", sans-serif;
    color: black;
    margin: 0;
    margin-left: 1rem;
}

.suggestCon img {
    width: 20px;
    height: 20px;
}

.suggestCon p {
    font-size: 15px;
    font-family: "Manrope", sans-serif;
    color: black;
    margin: 0;
    height: 4.2rem;
    overflow: auto;
}

.scoreBoxCon {
    padding: 0;
    margin: 0;
}


.scoreBox {
    background: #FBFBFD;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    padding: 1.5rem 0;
}

.scoreBox h3 {
    margin: 0;
}

.scoreBox h5 {
    margin: 0;
}

.miniPerc {
    font-size: 14px;
    margin-left: 5px;
    color: #3d3d3d;
    font-weight: 400;
}

.scoreBox h3 {
    margin-bottom: 7px;
    text-align: center;
}

.scoreBox h5 {
    color: #0262DF;
    margin-bottom: 0px;
    text-align: center;
    cursor: pointer;
}

.scoreBox hr {
    margin: 1rem 0;
    border-color: #ffffff38;
}

.padCon {
    padding: 0 20px;
}

.scoringGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scoringGrid img {
    height: 17px;
}

.scoringGrid h4 {
    margin: 10px 0;
    font-size: 15px;
}

.miniTitle {
    font-weight: 600;
}

.flex1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.overallCon {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(90deg, #006FFF 0%, #006FFF 0.01%, #0262DF 100%);
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 1rem;
}

.overallCon h4 {
    margin: 0;
    color: white;
}

.ideaImg {
    border-radius: 7px;
    padding: 6px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.ideaImg svg {
    height: 17px;
}

.logoCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.logo1 {
    height: 60px;
    cursor: pointer;
}

.logo2 {
    height: 20px;
    cursor: pointer;
}

.templateOuter {
    padding-top: 30px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.templateCon {
    background-color: rgb(2 98 223 / 5%);
    padding: 12px 0;
    border-radius: 15px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.templateCon img {
    height: 20px;
    margin: 0;
    /* margin-bottom: 12px; */
}

.templateCon h4 {
    margin: 0;
    text-align: center;
    color: black;
}

.templateCon h5 {
    margin: 0.7rem 0;
    font-weight: 500;
    line-height: 1.2rem;
    text-align: center;
}

.download {
    color: black;
    cursor: pointer;
}

.scoreBox button {
    background: #0262DF;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    width: 100%;
    font-weight: 550;
    font-size: 17px;
    padding: 0.5rem 1rem;
    margin-top: 1.6rem;
    margin-bottom: 0.7rem;
}

@media (max-width: 3000px) {}

@media (max-width: 1560px) {
    .scoreBoxCon {
        position: relative;
    }

    .scoreBoxCon {
        width: auto;
    }

    .innerCon {
        grid-template-columns: 20% 57% 23%;
    }
}

@media (max-width: 1400px) {
    .innerCon {
        grid-template-columns: 20% 56% 24%;
    }
}

@media (max-width: 1350px) {
    .templateCon h5 br {
        display: none;
    }
}

@media (max-width: 1200px) {
    .innerCon {
        grid-template-columns: 20% 56% 24%
    }
}

@media (max-width: 1100px) {

    /* .innerCon {
        grid-template-columns: 20% 56% 24%
    } */
    .con1 {
        margin: 2.5rem 0;
    }

    .feedbackCon h2 {
        font-size: 20px;
        margin-left: 0.5rem;
    }

    .feedbackCon img {
        width: 20px;
        height: 20px;
    }

    .feedbackCon p {
        font-size: 14px;
    }

    .suggestCon h2 {
        font-size: 20px;
        margin-left: 0.5rem;
    }

    .suggestCon img {
        width: 20px;
        height: 20px;
    }

    .suggestCon p {
        font-size: 14px;
    }

}

@media (max-width: 1000px) {
    .innerCon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .scoreBoxCon {
        padding: 0;
        margin: 0;
        width: 70%;
        margin: 0 auto;
    }

    .scoreBox {
        height: fit-content;
        margin-bottom: 1rem;
    }
}

@media (max-width: 870px) {
    /* .feedbackCon {
        width: 80% !important;
    }

    .suggestCon {
        width: 80% !important;
    }

    .scoreBoxCon {
        width: 80%;
    } */
}

@media (max-width: 600px) {
    .innerCon {
        max-width: 550px !important;
    }
}

@media (max-width: 550px) {
    .innerCon {
        max-width: 500px !important;
    }
}

@media (max-width: 500px) {
    .innerCon {
        max-width: 450px !important;
    }

    .feedbackCon h2 {
        font-size: 15px;
    }

    .feedbackCon img {
        width: 15px;
        height: 15px;
    }

    .feedbackCon p {
        font-size: 12px;
    }

    .suggestCon h2 {
        font-size: 15px;
    }

    .suggestCon img {
        width: 15px;
        height: 15px;
    }

    .suggestCon p {
        font-size: 12px;
    }

    .scoringGrid h4 {
        font-size: 14px;
    }

    .scoreBox h3 {
        font-size: 15px;
    }

    .overallCon h4 {
        font-size: 14px;
    }

    .overallCon {
        margin-top: 0.5rem;
    }

    .templateCon h4 {
        font-size: 15px;
    }

    .scoreBox {
        padding: 1rem 0;
    }

    .scoreBox hr {
        margin: 0.7rem 0;
    }
}

@media (max-width: 450px) {
    .innerCon {
        max-width: 400px !important;
    }

    .scoringGrid h4 {
        font-size: 12px;
    }

    .overallCon h4 {
        font-size: 12px;
    }

    .templateCon h5 {
        font-size: 12px;
    }

    .scoreBox h5 {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .innerCon {
        max-width: 350px !important;
    }
}

@media (max-width: 350px) {
    .innerCon {
        max-width: 320px !important;
    }
}