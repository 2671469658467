/* Navbar */

.navContainer {
  border: 1px solid transparent;
  height: 11vh;
  display: grid;
  grid-template-columns: 27% 46% 27%;
  position: relative;
  background-color: white;
}

.navContainer div:nth-child(1) {
  display: flex;
  justify-content: end;
  align-items: center;
}

.logoImage {
  /* width: 200px; */
  height: 60px;
  margin-bottom: 5px;
}

.navContainer nav:nth-child(2) {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
}

.navContainer div:nth-child(3) {
  display: flex;
  justify-content: start;
  align-items: center;
}

.logoLink {
  cursor: pointer;
  text-decoration: none;
}

.navMenu a {
  color: black;
  font-family: "manrope", sans-serif;
  font-size: 17px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.navLink:last-child {
  padding-right: 0;
}

.goBackTxt {
  color: #0262df;
  cursor: pointer;
}

.dashboardLink {
  cursor: pointer;
  text-decoration: none;
}

.dashboardIcon {
  color: #0262df;
  font-size: 35px;
}

.authButtons {
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 1100px) {
  .navContainer nav:nth-child(2) {
    gap: 1rem;
  }
}

@media (max-width: 1100px) {
  .tryFreeButton {
    padding: 0.3rem 1rem;
  }

  .signInButton {
    padding: 0.3rem 1.5rem;
  }
}

@media (max-width: 900px) {
  .logoLink {
    display: none;
  }
}

@media (max-width: 700px) {
  .navContainer {
    grid-template-columns: 0% 0% 100%;
  }

  .navContainer div:nth-child(3) {
    justify-content: center;
  }
}

.section {
  min-height: 83vh;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundImage {
  background-color: #fbfbfd;
}

.card {
  background: white;
  padding: 1.5rem;
  padding-top: 6.5rem;
  border-radius: 16px;
  width: fit-content;
  text-align: left;
  border: none;
  position: relative;
  width: 28rem;
  box-shadow: 0px 0px 13px 2px #0000000d;
}

.bgPopup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9rem;
  background: #0262df;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 15px 15px 0 0;
}

.curveCon {
  position: relative;
  bottom: -1px;
  height: 2.5rem;
  width: 100%;
  background: white;
  border-radius: 50% 50% 0 0;
}

.card form {
  position: relative;
  z-index: 2;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.section3 footer {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 2rem;
  gap: 10px;
}

.section3 footer > h5{
    color: white;
}

.section2 footer button,
.section3 footer button {
  border: none;
  border-radius: 7px;
  background-color: white;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 7px;
  color: black;
  cursor: pointer;
}

.section2 footer button h5,
.section3 footer button h5 {
  font-size: 14px;
  font-weight: 600;
}

.cardCon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px 25px;
  border-radius: 8px;
}

.cardCon h5 {
  margin: 0;
}

.cardCon button {
  border: none;
  background-color: var(--blue);
  border-radius: 7px;
  padding: 7px 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.cardCon button h5 {
  color: white;
  font-size: 15px;
}

.cardCon a {
  text-decoration: none;
}


.imgCloud {
  height: 2.5rem;
  margin-bottom: 10px;
}

.chooseFile {
  height: 1.2rem;
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: black;
}

.text1 {
  font-size: 16px;
  margin: 15px 0;
}

.text2 {
  font-size: 15px;
  text-align: center;
}

.text3 {
  font-size: 14px;
  color: var(--blue);
}

.input {
  border: 1px solid rgba(122, 122, 122, 0.5);
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: black;
  padding: 7px 15px;
  box-sizing: border-box;
  margin: 0.8em 0;
  outline: none;
}

.input::placeholder {
  color: grey;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.btn1,
.btn2 {
  background: none;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 7px 15px;
  cursor: pointer;
  width: 100%;
}

.btn1 h5,
.btn2 h5 {
  margin: 0;
  font-size: 14px !important;
}

.m1r {
  margin: 1rem 0 !important;
}

.section3 {
  min-height: 89vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.s4InnerMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 3rem;
}

.uploadMain {
  display: flex;
  justify-content: center;
  align-items: center;
}


.title {
  font-weight: 700;
  font-size: 20px;
}

.text1 {
  font-size: 16px;
  margin: 15px 0;
}

.text2 {
  font-size: 15px;
  text-align: center;
}

.text3 {
  font-size: 14px;
  color: var(--blue);
}

.text5 {
  font-weight: 600;
  text-align: center;
}

.text4 {
  font-weight: 700;
  text-align: center;
  margin-top: 0.5rem;
  cursor: pointer;
}
