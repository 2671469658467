:root {
  --primary-text: #3f3f3f;
  --secondary-text: #767676;
}

@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700");

.banner {
  background-color: white;
  padding: 17px 5%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* box-shadow: 0px 4px 12px rgb(0 0 0 / 7%); */
}

.fw600 {
  font-weight: 600;
}

.banner h5 {
  margin: 0;
}

.hero {
  margin: 0;
  padding: 20px 5%;
  background: #f8f8f8;
  border-radius: 30px 0 0 0;
  height: 100vh;
}

.flexButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.pricingBtns {
  max-width: 310px;
  width: fit-content;
  padding: 0.2rem;
  border-radius: 25px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

}

.saveText{
    color: #606060;
    font-size: 12px;
}

/* .pricingBtns button {
  background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important;
} */

.pricingBtns button h5{
    font-size: 14px;
    font-weight: 600;
}

.toggleButton {
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    width: 6.5rem;
    height: 2rem;
    border: 1px solid transparent;
    /* transition: background-color 0.3s, color 0.s; */
}

.pricingMain {
  /* height: 100%; */
  display: grid;
  grid-template-columns: 22% 24% 24% 24%;
  gap: 2%;
}

.pricingElem {
  border: 1.5px solid #e1e1e1;
  border-radius: 15px;
  padding: 2rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 9rem;
}

.title1 {
  color: black;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.title2 {
  font-size: 16px;
  font-weight: 600;
}

.price {
  font-size: 30px;
  font-weight: 900;
  color: black;
}

.priceRep {
  font-size: 24px;
}

.price span {
  font-size: 18px;
  font-weight: 600;
}

.text {
  font-size: 15px;
  font-weight: 500;
  color: var(--primary-text);
}

.textBold {
  text-align: end;
  font-size: 15px;
  font-weight: 700;
  color: var(--primary-text);
}

.textLight {
  font-size: 15px;
  font-weight: 500;
  color: var(--secondary-text);
}

.textLightMini {
  font-size: 13px;
  font-weight: 500;
  color: var(--secondary-text);
  height: 2rem;
  margin-top: 10px;
}

.btnCon {
  margin-top: 10px;
}

.btnCon button {
  border: none;
  background: none;
  background: #12a8dd;
  border-radius: 10px;
  cursor: pointer;
  /* height: 2.2rem; */
  width: 7rem;
}

.btnCon button h5 {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.btnHeight {
  height: 2.2rem;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
}

.flexEnd {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  height: 2.5rem;
}

.tooltipCon {
  position: relative;
  display: flex;
  justify-content: center;
}

.tooltipHead {
  background: #686868;
  color: white;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tooltipHead h5 {
  font-size: 10px;
  margin-right: 1px;
}

.tooltipBody {
  background: #686868;
  position: absolute;
  bottom: 18px;
  padding: 6px 9px;
  width: 11rem;
  border-radius: 8px;
  opacity: 0;
  z-index: -1;
  transition: all 0.2s;
}

.tooltipHead:hover + .tooltipBody {
  bottom: 22px;
  z-index: 1;
  opacity: 1;
}

.tooltipBody h5 {
  font-size: 10px;
  text-align: center;
  color: white;
}

.padding3 {
  padding: 2rem 0;
}

@media (max-width: 1400px) {
  .hero {
    padding: 40px 1%;
  }
  .pricingElem {
    padding: 3rem 0;
  }
  .padding3 {
    padding: 3rem 0;
  }
}

@media (max-width: 1200px) {
  .price {
    font-size: 22px;
  }
  .price span {
    font-size: 16px;
  }
  .priceRep {
    font-size: 18px;
  }
  .title1 {
    font-size: 16px;
  }
  .textLight {
    font-size: 13px;
  }
  .textLightMini {
    font-size: 12px;
  }
  .btnCon button h5 {
    font-size: 12px;
  }
  .btnHeight {
    height: 2rem;
  }
  .text {
    font-size: 13px;
  }
  .textBold {
    font-size: 13px;
  }
  .tooltipHead {
    height: 12px;
    width: 12px;
  }
  .tooltipHead h5 {
    font-size: 7px;
  }
}

@media (max-width: 1000px) {
  .pricingElem {
    padding: 3rem 2rem;
  }
  .padding3 {
    padding: 3rem 2rem;
  }
  .pricingMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1rem;
  }
}
