:root {
  --black: black;
  --blue: #0262df;
}

.popup {
  background-color: #00000057;
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.card {
  background: white;
  padding: 1.5rem;
  padding-top: 7rem;
  border-radius: 15px;
  width: fit-content;
  text-align: left;
  border: none;
  position: relative;
  width: 28rem;
}

.bgPopup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9rem;
  background: #0262df;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 15px 15px 0 0;
}

.curveCon {
  position: relative;
  bottom: -1px;
  height: 2.5rem;
  width: 100%;
  background: white;
  border-radius: 50% 50% 0 0;
}

.card form {
  position: relative;
  z-index: 2;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardCon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px 0;
  border-radius: 8px;
}

.cardCon h5 {
  margin: 0;
}

.cardCon button h5 {
  color: white;
  font-size: 15px;
}

.cardCon a {
  text-decoration: none;
}

.title {
  font-weight: 700;
  font-size: 18px;
  margin-top: 1rem;
  text-align: center;
}

.text1 {
  font-size: 16px;
  margin: 15px 0;
}

.text2 {
  font-size: 15px;
  text-align: center;
}

.text3 {
  font-size: 14px;
  color: var(--blue);
}

.input {
  border: 1px solid rgba(122, 122, 122, 0.5);
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: black;
  padding: 7px 15px;
  box-sizing: border-box;
  margin: 0.8em 0;
  outline: none;
}

.input::placeholder {
  color: grey;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.btn1,
.btn2 {
  background: none;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 11px 15px;
  cursor: pointer;
  width: 100%;
}

.btn1 h5,
.btn2 h5 {
  margin: 0;
  font-size: 14px !important;
}

.m1r {
  margin: 1rem 0 !important;
}

.scoreInputText {
  border: none;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  width: 50%;
  outline: none;
  overflow: hidden !important;
  margin-bottom: 10px;
  color: black;
  margin-top: 1rem;
}

.text4 {
  text-align: start;
  width: 100%;
  font-weight: 600;
  padding-left: 10px;
}

.addComment {
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 3.2rem;
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px -3px 14px rgb(0 0 0 / 7%);
  border-radius: 15px;
  margin: 0.5rem 0 2rem 0;
}

.addComment textarea {
  width: 80%;
  border: none;
  height: 100%;
  outline: none;
  resize: none;
  overflow: hidden;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: black;
}

.addComment textarea::placeholder {
  color: #989898;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.scoreInputText::-webkit-outer-spin-button,
.scoreInputText::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.changeScoreTxt {
  text-align: center;
  font-size: 13px;
  margin-bottom: 2rem !important;
  cursor: pointer;
}
