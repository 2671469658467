.myTemplateSwiper{
    height: 100%;
}

/* .swiper-wrapper{
    border: 1px solid black;
} */

/* .swiper-slide{
    border: 1px solid black;
} */

/* .swiper-wrapper div:nth-child(1)  {
    width: fit-content;
    box-shadow: rgb(0 0 0 / 28%) 0px 0px 10px;
}

.swiper-wrapper div:nth-child(2)  {
    width: fit-content;
    box-shadow: rgb(0 0 0 / 28%) 0px 0px 10px;
}

.swiper-wrapper div:nth-child(2)  {
    width: fit-content;
    box-shadow: rgb(0 0 0 / 28%) 0px 0px 10px;
} */


