:root {
    --black: black;
    --blue: #0262DF;
}

.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    padding-top: 6rem;
    border-radius: 15px;
    width: 28rem;
    text-align: left;
    border: none;
    position: relative;
}

.bgPopup{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9rem;
    background: #0262DF;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-radius: 15px 15px 0 0;
}

.curveCon{
    position: relative;
    bottom: -1px;
    height: 2.5rem;
    width: 100%;
    background: white;
    border-radius: 50% 50% 0 0;
}

.card form{
    position: relative;
    z-index: 2;
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    z-index: 2;
    background: white;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.close svg {
    height: 10px;
}

.cardCon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 20px 25px;
    border-radius: 8px;
}

.cardCon h5 {
    margin: 0;
}

.cardCon button h5 {
    color: white;
    font-size: 15px;
}

.cardCon a {
    text-decoration: none;
}

.title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 10px;
}

.text1 {
    font-size: 16px;
    margin: 15px 0;
}

.text2 {
    font-size: 15px;
    text-align: center;
}

.text3 {
    font-size: 14px;
    color: var(--blue);
}

.input {
    border: 1px solid rgba(122, 122, 122, 0.5);
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    color: black;
    padding: 7px 15px;
    box-sizing: border-box;
    margin: 0.8em 0;
    outline: none;
}

.input::placeholder {
    color: grey;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.btn1,
.btn2 {
    background: none;
    outline: none;
    border: none;
    border-radius: 7px;
    padding: 7px 15px;
    cursor: pointer;
    width: 100%;
}

.btn1 h5,
.btn2 h5 {
    margin: 0;
    font-size: 14px !important;
}

.m1r{
    margin: 1rem 0 !important;
}

.bannerMid {
    border: 1px solid black;
    border-radius: 3px;
    height: 2.5rem;
    padding: 4px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
}

.bannerMid h5 {
    font-size: 13px;
}

.bmLeft {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 6rem;
    padding: 0 7px;
}

.bmRight {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 7px;
}

.flexAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 11px;
}
