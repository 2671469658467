@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#home {
  display: flex;
  justify-content: center;
  /* margin-top: width > 700 ? "6rem" : "1rem",  */
  text-align: center;
}

.home {
  min-height: 55vh;
}

.customBox {
  text-align: center;
  padding: 20px;
  /* background-color: #fff; */
}

.home_btns_con p {
  margin: 1rem !important;
  color: #475467c4 !important;
  font-weight: 550 !important;
  font-size: 14px !important;
  font-family: 'manrope', sans-serif !important;
}

.home_btns {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2rem;
}

@media (min-width: 800px) {
  /* .custom-container {
    flex-direction: row;
  } */
}

.Txt1 {
  font-weight: 550 !important;
  color: black !important;
  font-size: 75px !important;
  font-family: sans-serif, 'manrope' !important;
}

.Txt2 {
  font-family: sans-serif, 'manrope' !important;
  margin-top: 20px !important;
  font-size: 18px !important;
  color: black !important;
}

.bgContainer {
  width: 100%;
  height: 400px;
  /* Default height */
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.large {
  height: 700px;
  /* Height for width > 700 */
}

.bgContainer img {
  height: auto;
  width: 100%;
}

.gradientText {
  font-weight: 550;
  color: #0262DF;
  font-size: 75px;
  font-family: sans-serif, 'manrope';
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  color: #0262DF;
  font-size: 2.26rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animation {
  height: 6rem;
  overflow: hidden;
  font-weight: 550;
  color: #0262DF;
  font-size: 75px;
  font-family: sans-serif, 'manrope';
}

.animation>div>div {
  /* border: 1px solid blueviolet; */
  /* padding: 0.25rem 0.75rem; */
  height: 100%;
  width: 100%;
  text-align: center;
  /* margin-bottom: 2.81rem; */
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
  animation-delay: 2.5s;
}

.first {
  height: 6rem;
  
}

.second {
  height: 6rem;
  
}

.third {
  height: 6rem;
  
}

.first div {
  /* background-color: #20a7d8; */
}

.second div {
  /* background-color: #CD921E; */
}

.third div {
  /* background-color: #c10528; */
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -6rem;
  }

  30% {
    margin-top: -6rem;
  }

  40% {
    margin-top: -6rem;
  }

  50% {
    margin-top: -12rem;
  }

  60% {
    margin-top: -12rem;
  }

  70% {
    margin-top: -12rem;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes text-animation2 {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -5rem;
  }

  30% {
    margin-top: -5rem;
  }

  40% {
    margin-top: -5rem;
  }

  50% {
    margin-top: -10rem;
  }

  60% {
    margin-top: -10rem;
  }

  70% {
    margin-top: -10rem;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes text-animation3 {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -4.5rem;
  }

  30% {
    margin-top: -4.5rem;
  }

  40% {
    margin-top: -4.5rem;
  }

  50% {
    margin-top: -9rem;
  }

  60% {
    margin-top: -9rem;
  }

  70% {
    margin-top: -9rem;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes text-animation4 {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -4rem;
  }

  30% {
    margin-top: -4rem;
  }

  40% {
    margin-top: -4rem;
  }

  50% {
    margin-top: -8rem;
  }

  60% {
    margin-top: -8rem;
  }

  70% {
    margin-top: -8rem;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes text-animation5 {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -2.5rem;
  }

  30% {
    margin-top: -2.5rem;
  }

  40% {
    margin-top: -2.5rem;
  }

  50% {
    margin-top: -5rem;
  }

  60% {
    margin-top: -5rem;
  }

  70% {
    margin-top: -5rem;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes text-animation6 {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -1.8rem;
  }

  30% {
    margin-top: -1.8rem;
  }

  40% {
    margin-top: -1.8rem;
  }

  50% {
    margin-top: -3.6rem;
  }

  60% {
    margin-top: -3.6rem;
  }

  70% {
    margin-top: -3.6rem;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}

@media (max-width: 1920px) {
  .home {
    margin-top: 4%;
  }
}

@media (max-width: 1500px) {
  .home {
    margin-top: 2.5rem;
  }
}

@media (max-width: 1200px) {
  .home {
    min-height: 60vh;
  }

  .Txt1 {
    font-size: 65px !important;
  }

  .gradientText {
    font-size: 65px !important;
  }

  .animation {
    font-size: 65px !important;
    height: 5rem;
    /* padding-top: 6px; */
  }

  .first {
    height: 5rem;
    
  }
  
  .second {
    height: 5rem;
    
  }
  
  .third {
    height: 5rem;
    
  }

  .animation div:first-child {
    animation: text-animation2 8s infinite;
  }

  .Txt2 {
    font-size: 16px !important;
  }

  .home {
    min-height: 40vh;
  }
}

@media (max-width: 1000px) {
  .home {
    min-height: 55vh;
  }

  .Txt1 {
    font-size: 55px !important;
  }

  .gradientText {
    font-size: 55px !important;
  }

  .animation {
    font-size: 55px !important;
    height: 4.5rem;
  }

  .first {
    height: 4.5rem;
    
  }
  
  .second {
    height: 4.5rem;
    
  }
  
  .third {
    height: 4.5rem;
    
  }

  .animation div:first-child {
    animation: text-animation3 8s infinite;
  }

  .Txt2 {
    font-size: 15px !important;
  }
}

@media (max-width: 800px) {
  .Txt1 {
    font-size: 40px !important;
  }

  .gradientText {
    font-size: 40px !important;
  }

  .animation {
    font-size: 40px !important;
    height: 4rem;
  }

  .first {
    height: 4rem;
    
  }
  
  .second {
    height: 4rem;
    
  }
  
  .third {
    height: 4rem;
    
  }

  .animation div:first-child {
    animation: text-animation4 8s infinite;
  }

  .Txt2 {
    font-size: 14px !important;
  }

  .bgContainer {
    top: 10%;
  }
}

@media (max-width: 600px) {
  .Txt1 {
    font-size: 30px !important;
  }

  .gradientText {
    font-size: 30px !important;
  }

  .animation {
    font-size: 30px !important;
    height: 2.5rem;
  }

  .first {
    height: 2.5rem;
    
  }
  
  .second {
    height: 2.5rem;
    
  }
  
  .third {
    height: 2.5rem;
    
  }

  .animation div:first-child {
    animation: text-animation5 8s infinite;
  }

  .Txt2 {
    font-size: 13px !important;
  }

  .Txt2 br {
    display: none !important;
  }

  .customBox h2 {
    font-size: 2rem !important;
  }

  .home_btns_con p {
    font-size: 11px !important;
    margin: 0.5rem !important;
  }
}

@media (max-width: 480px) {
  .home {
    min-height: 40vh;
  }

  .Txt1 {
    font-size: 22px !important;
  }

  .gradientText {
    font-size: 22px !important;
  }

  .animation {
    font-size: 22px !important;
    height: 1.8rem;
    /* height: 27px; */
  }

  .first {
    height: 1.8rem;
    
  }
  
  .second {
    height: 1.8rem;
    
  }
  
  .third {
    height: 1.8rem;
    
  }

  .animation div:first-child {
    animation: text-animation6 8s infinite;
  }

  .Txt2 {
    font-size: 11px !important;
  }

  .customBox h2 {
    font-size: 1.2rem !important;
  }

  .customBox {
    padding: 35px 20px 20px 20px;
  }

  .customBox svg {
    padding-right: 0.2rem !important;
    height: 20px;
    width: 20px;
  }

  .home_btns_con p {
    font-size: 10px !important;
    margin: 0.5rem !important;
  }

  .home_btns {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}