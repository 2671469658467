.logo {
    margin-top: 1.5rem;
    margin-left: 0rem;
    cursor: pointer;
    margin-bottom: 1rem;
}

.logo img {
    height: 60px;
}

.premiumBox {
    /* background-color: black; */
    border: 2px solid #e7e7e7b3;
    margin: 0 10px;
    margin-bottom: 15px;
    border-radius: 7px;
    padding: 15px;
    color: black;
}

.premiumBox>h5 {
    font-weight: 500;
    text-align: start;
    margin: 0;
    cursor: default;
    color: black;
    font-size: 14px;
}

.flexAlign{
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 5px !important;
}

.upgradeTitle{
    font-weight: 800 !important;
}

.upgradeText{
    font-size: 13px !important;
}

.fw700 {
    font-weight: 700 !important;
}

.fs13{
    font-size: 13px;
}

.progressLine{
    background: white;
    border-radius: 5px;
    height: 12px;
    width: 100%;
    margin: 10px 0;
}

.premiumBox img {
    height: 18px;
}

.premiumBtn {
    color: white;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #0262DF;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px !important;
    cursor: pointer !important;
}

.premiumBtn:hover {
    /* background-color: #013d8b !important; */
}

.premiumBtn2{
    background: none !important;
    border: 1px solid #E7E7E7 !important;
    cursor: default !important;
}

.premiumBtn2 > span{
    color: black !important;
    font-weight: 700 !important;
}

.premiumBtn span {
    background-image: none;
    font-family: "Manrope", Helvetica, Arial, sans-serif;
    font-size: 13px;
    position: relative;
    padding: initial;
    display: block;
    height: auto;
    line-height: normal;
    color: rgb(255, 255, 255) ;
    font-weight: 500;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;
    transition-property: all;
    transition-duration: 0.3s;
}

.premiumBtn:hover .upgradeSpan{
    font-weight: 700 !important;
}

.premiumBtn>h5 {
    font-size: 12px;
    margin: 0;
}

.flexAlignEnterprise{
    display: flex !important;
    align-items: center;
}

.flexAlignEnterprise > span{
    color: black;
    font-size: 25px;
    line-height: 10px;
    margin-right: 5px;
}

.manrope{
    font-family: 'Manrope', sans-serif !important;
}