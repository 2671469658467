:root {
  --black: black;
  --blue: #0262df;
}

.popup {
  background-color: #00000057;
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 4.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.card {
  background: white;
  border-radius: 10px;
  width: fit-content;
  text-align: left;
  border: none;
  position: relative;
  width: 45rem;
  min-height: 25rem;
}

.close {
  position: absolute;
  right: 9px;
  top: 4px;
  cursor: pointer;
}

.close svg {
  height: 0.5rem;
}

.cardCon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px 25px;
  border-radius: 8px;
}

.cardCon h5 {
  margin: 0;
}

.cardCon button h5 {
  color: white;
  font-size: 15px;
}

.cardCon a {
  text-decoration: none;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.text1 {
  font-size: 16px;
  margin: 15px 0;
}

.text2 {
  font-size: 15px;
  text-align: center;
}

.text3 {
  font-size: 14px;
  color: var(--blue);
}

.input {
  /* border: 1px solid rgba(122, 122, 122, 0.5); */
  border: none;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: black;
  padding: 7px 15px;
  box-sizing: border-box;
  margin: 0.8em 0;
  outline: none;
}

.input::placeholder {
  color: grey;
}

.textArea {
  /* border: 1px solid rgba(122, 122, 122, 0.5); */
  border: none;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: black;
  padding: 7px 15px;
  box-sizing: border-box;
  margin: 0.8em 0;
  outline: none;
}

.textArea::placeholder {
  color: grey;
}

.btn {
  border: none;
  border-radius: 8px;
  padding: 7px 15px;
  cursor: pointer;
}

.btn h5 {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.select {
  /* border: 1px solid rgba(122, 122, 122, 0.5); */
  border-radius: 8px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: black;
  padding: 7px 15px;
  box-sizing: border-box;
  outline: none;
}

.select::placeholder {
  color: grey;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.btn1,
.btn2 {
  background: none;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 7px 15px;
  cursor: pointer;
  width: 100%;
}

.btn1 h5,
.btn2 h5 {
  margin: 0;
  font-size: 14px !important;
}

.m1r {
  margin: 1rem 0 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 1rem;
  border-radius: 8px 8px 0 0;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flexAlignEnd {
  display: flex;
  align-items: end;
  gap: 10px;
}

.body {
  padding: 5px 1rem;
}

.line {
  border: none;
  border-bottom: 1px solid rgba(122, 122, 122, 0.318);
}

.flexBetween {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 7px 0;
}

.toolbarCategory {
  display: flex;
  align-items: center;
  gap: 5px;
}

.toolbarCategory button {
  background: #f2f2f2;
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  height: 23px;
  width: 23px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbarCategory button:hover {
  background: #ebebeb;
}

.toolbarCategory > button > img {
  height: 18px;
}

.editor {
  width: 100%;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  background: white;
  height: 20rem;
  padding: 5px 15px;
  font-size: 14px;
}

.editor > div {
  height: 100%;
  overflow: auto;
}

