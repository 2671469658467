:root {
    --black: black;
    --blue: #0262DF;
}

.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: white;
    padding: 1.7rem 2rem 2rem 2rem;
    padding-top: 9rem;
    border-radius: 15px;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0px 4px 12px #0362e038; */
    width: 28rem;
    /* min-width: 200px; */
    /* height: max-content; */
    text-align: left;
    border: none;
    /* border-color: #0262DF; */
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.header img {
    width: 2.5rem;
}

.header h5 {
    margin: 0;
    color: #676767;
    text-align: center;
}

.text{
    font-size: 14px;
    margin: 10px 0 !important;
}

.planName {
    color: var(--black);
    margin: 0px;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.334;
    letter-spacing: 0em;
    text-align: center;
}

.price {
    font-size: 42px;
    margin-top: -0.2rem;
    color: var(--black);
    /* border-bottom: 2px solid #b8d7ff; */
    margin-top: 1.2rem;
    padding-bottom: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.colorWhite {
    color: white;
}

.priceSuffix {
    font-size: 18px;
    font-weight: 400;
    margin-left: 7px;
}

.card1_p {
    margin: 0;
    margin-left: -1.5rem;
    font-size: 15px;
    font-weight: 500;
    /* line-height: 1rem; */
    color: var(--black);
}

.premiumBtn {
    border: none !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    background: #0262DF;
    padding: 8px !important;
    border-radius: 5px !important;
    margin-top: 10px !important;
    cursor: pointer !important;
    width: 100% !important;
    font-family: "Manrope", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    line-height: normal !important;
    transition-property: all !important;
    transition-duration: 0.3s !important;
}

.premiumBtn:hover {
    /* background-color: #013d8b !important; */
}

.premiumBtn>span {
    background-image: none !important;
    font-family: "Manrope", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    position: relative !important;
    padding: initial !important;
    display: block !important;
    height: auto !important;
    line-height: normal !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 500 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-radius: 0 !important;
}

.premiumBtn:hover>span {
    font-weight: 700 !important;
}

.premiumBtn>h5 {
    font-size: 14px;
    margin: 0;
}

.premiumBtn img {
    height: 18px;
}

.bgPopup{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9rem;
    background: #0262DF;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-radius: 15px 15px 0 0;
}

.curveCon{
    position: relative;
    bottom: -1px;
    height: 2.5rem;
    width: 100%;
    background: white;
    border-radius: 50% 50% 0 0;
}

.card form{
    position: relative;
    z-index: 2;
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    z-index: 2;
    background: white;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.close svg {
    height: 10px;
}