/* Navbar */
:root {
  --black: black;
  --blue: #0262df;
}

.navContainer {
  border: 1px solid transparent;
  height: 11vh;
  display: grid;
  grid-template-columns: 27% 46% 27%;
  position: relative;
  background-color: white;
}

.navContainer div:nth-child(1) {
  display: flex;
  justify-content: end;
  align-items: center;
}

.logoImage {
  /* width: 200px; */
  height: 60px;
  margin-bottom: 5px;
}

.navContainer nav:nth-child(2) {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
}

.navContainer div:nth-child(3) {
  display: flex;
  justify-content: start;
  align-items: center;
}

.logoLink {
  cursor: pointer;
  text-decoration: none;
}

.navMenu a {
  color: black;
  font-family: "manrope", sans-serif;
  font-size: 17px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.navLink:last-child {
  padding-right: 0;
}

.goBackTxt {
  color: #0262df;
  cursor: pointer;
}

.dashboardLink {
  cursor: pointer;
  text-decoration: none;
}

.dashboardIcon {
  color: #0262df;
  font-size: 35px;
}

.authButtons {
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 1100px) {
  .navContainer nav:nth-child(2) {
    gap: 1rem;
  }
}

@media (max-width: 1100px) {
  .tryFreeButton {
    padding: 0.3rem 1rem;
  }

  .signInButton {
    padding: 0.3rem 1.5rem;
  }
}

@media (max-width: 900px) {
  .logoLink {
    display: none;
  }
}

@media (max-width: 700px) {
  .navContainer {
    grid-template-columns: 0% 0% 100%;
  }

  .navContainer div:nth-child(3) {
    justify-content: center;
  }
}

.section {
  min-height: 83vh;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section2 {
  height: 89vh;
  position: relative;
  padding: 3rem 0 6rem 0;
  display: flex;
  justify-content: center;
  align-items: start;
}

.section3 {
  min-height: 89vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.msgCard {
  width: 75%;
  height: 95%;
  overflow: auto;
  padding-right: 10px;
}

.msgCard::-webkit-scrollbar {
  width: 10px;
}

.msgCard::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.015) -122%,
    #f3f3f3 95%
  );
  border-radius: 7px;
}

.msgCard::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d12 90%
  );
  border-radius: 7px;
}

.msgCard::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d23 90%
  );
  border-radius: 7px;
}

.msgCard h5 {
  font-size: 17px;
  font-weight: 400;
}

.section2 footer {
  height: 5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 2rem;
}
.section3 footer {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 2rem;
  gap: 10px;
}

.section3 footer > h5{
    color: white;
}

.section2 footer button,
.section3 footer button {
  border: none;
  border-radius: 7px;
  background-color: white;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 7px;
  color: black;
  cursor: pointer;
}

.section2 footer button h5,
.section3 footer button h5 {
  font-size: 14px;
  font-weight: 600;
}

.section3Inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}

.backgroundImage {
  background-color: #fbfbfd;
}

.boxStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 28rem;
  padding: 3rem 3rem;
  background: white;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.boxStyles2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 35rem;
}

.mb15 {
  margin-bottom: 15px !important;
}

.miniLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.miniLogo img {
  height: 3.5rem;
}

.heading {
  font-family: "Manrope", sans-serif;
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.miniTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.preDomain {
  padding: 0px 12px;
  background: rgba(2, 98, 223, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.preDomain h5 {
  margin: 0;
  font-size: 13px;
  color: #565656;
}

.passwordCon {
  padding: 5px 15px;
  padding-left: 1rem;
  border-radius: 8px;
  height: 44px;
  border: 1px solid rgba(230, 232, 236, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.passwordCon input {
  height: 92%;
  width: 80%;
  font-size: 15px;
  font-family: "Manrope", sans-serif;
  border: none;
  background: none;
  outline: none;
}

.passwordCon input:-webkit-autofill {
  height: 92%;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.passwordCon svg {
  height: 15px;
  fill: #242436;
  cursor: pointer;
}

.textWithLines {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.textWithLines hr {
  border-color: #ebebeb3d;
  margin: 0;
  width: 100%;
}

.textWithLines span {
  font-size: 13px;
  font-family: "Manrope", sans-serif;
}

.inputField {
  padding-left: 1rem;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  border-radius: 8px;
  height: 44px;
  color: #242436;
  border: 1px solid rgba(230, 232, 236, 1);
  outline: none;
  font-size: 15px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 7px;
}

.inputField:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
}

.inputError {
  border: 2px solid #f61d1d;
}

.error {
  margin: 0;
  font-size: 13px;
  color: #f61d1d;
}

.success {
  margin: 0;
  font-size: 13px;
  color: green;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  font-size: 14px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  color: rgba(78, 86, 109, 1);
  border: 1px solid rgba(230, 232, 236, 1);
  cursor: pointer;
  margin-top: 1rem;
  gap: 10px;
}

.googleText {
  font-family: "Manrope", sans-serif;
}

.googleIcon {
  height: 1.2rem;
}

.signInButton {
  background: #0362e0;
  color: white;
  border: 1px solid transparent;
  font-family: "Manrope", sans-serif;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.flexAlign {
  display: flex;
  align-items: center;
}

.check {
}

.forgotPasswordButton {
  /* color: #0362e0; */
  color: black;
  cursor: pointer;
  border-color: transparent;
  background: transparent;
  font-size: 13px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  text-align: start;
  margin: 0;
  padding: 0;
  max-width: 20rem;
}

.forgotPasswordButton:hover {
  text-decoration: underline;
}

.signupLink {
  gap: 5px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  margin: 0;
  /* margin-bottom: 11px; */
  line-height: 1rem;
  max-width: 20rem;
}

.signupLink2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 11px;
  line-height: 1rem;
  /* cursor: pointer; */
}

/* .signupLink2:hover {
    text-decoration: underline;
} */

.linkButton {
  color: #0362e0;
  cursor: pointer;
  border-color: transparent;
  background: transparent;
  font-size: 12px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  padding: 0;
  margin: 0;
  line-height: 1rem;
}

.flex3 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 4px;
}

/* @media (max-width: 1560px) {
    .boxStyles{
        min-width: 24rem;
        max-width: 24rem;
    }
} */
@media (max-width: 480px) {
  .section {
    align-items: start;
    margin-top: 2rem;
  }

  .boxStyles {
    min-width: 15rem;
    padding: 2rem 2rem;
  }

  .miniLogo {
    height: 3rem;
  }

  .heading {
    margin-bottom: 5px;
    margin-top: 8px;
    font-size: 19px;
  }

  .subtitle {
    font-size: 13px;
  }

  .inputField {
    font-size: 14px;
    height: 35px;
  }

  .passwordCon {
    height: 35px;
  }

  .passwordCon input {
    font-size: 14px;
  }

  .signInButton {
    font-size: 13px;
    height: 35px;
  }
}

.section4 {
  min-height: 89vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.s4InnerMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 3rem;
}

.uploadMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  width: fit-content;
  text-align: left;
  border: none;
  position: relative;
}

.close {
  position: absolute;
  right: 9px;
  top: 4px;
  cursor: pointer;
}

.close svg {
  height: 0.5rem;
}

.cardCon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px 25px;
  border-radius: 8px;
}

.cardCon h5 {
  margin: 0;
}

.cardCon button {
  border: none;
  background-color: var(--blue);
  border-radius: 7px;
  padding: 7px 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.cardCon button h5 {
  color: white;
  font-size: 15px;
}

.cardCon a {
  text-decoration: none;
}

.imgCloud {
  height: 2.5rem;
  margin-bottom: 10px;
}

.chooseFile {
  height: 1.2rem;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.text1 {
  font-size: 16px;
  margin: 15px 0;
}

.text2 {
  font-size: 15px;
  text-align: center;
}

.text3 {
  font-size: 14px;
  color: var(--blue);
}

.text5 {
  font-weight: 600;
  text-align: center;
}

.text4 {
  font-weight: 700;
  text-align: center;
  margin-top: 0.5rem;
  cursor: pointer;
}
