.FeatureCard {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Apply transition to both transform and box-shadow */
  /* Rest of your styles */
}

.FeatureCard:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(45, 94, 255, 0.4);
}

.myBG {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: "#E5E5E5";
  background-image: url('./line.png');

  opacity: 0.2,
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo-animate {
  opacity: 0;
  /* Start hidden */
  animation-name: fadeInUp;
  animation-fill-mode: forwards;
  /* Stay in final state after animation */
  animation-duration: 0.5s;
  /* Adjust duration to your liking */
  /* Animation delay will be set inline to stagger the animations */
}


.animate-on-scroll-c {
  animation: fadeInUp 2s ease-in-out forwards, fadeInUp 2s forwards;
}

.howItWorks {
  text-align: center;
  position: relative;
}

.howItWorks div:nth-child(1) {
  margin-top: 3rem;
}

.howItWorks div:nth-child(1) h1 {
  font-size: 45px;
  font-family: sans-serif, 'manrope';
  color: black;
  margin: 0;
}

.cardsContainer {
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  padding: 4rem 2rem 0 2rem;
  margin-bottom: 8rem;
  display: flex;
  justify-content: center;
  position: relative;
  gap: 1.5rem;
}

/* @media (min-width: 700px) {
  .cardsContainer {
    margin-top: 5%;
  }
} */

/* @media (max-width: 700px) {
  .cardsContainer {
    margin-top: 1%;
  }
} */

.lineContainer {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 915px;
  pointer-events: none;
  z-index: -1;
}

.lineImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.featureCard {
  padding: 1.8rem;
  background: transparent;
  max-width: 21rem;
  text-align: left;
  margin: 0.3rem;
  cursor: pointer;
  position: relative;

}

.cardBg {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: 0 !important;
  padding: 0 !important;
  transition-property: all;
  transition-duration: 0.3s;
  z-index: -1;
}

.featureCard:hover .cardBg {
  transform: scale(1.05);
}

.iconImage {
  width: 70px;
  height: 60px;
}

.cardTitle {
  font-size: 18px;
  margin-top: -0.2rem;
  color: black;
  font-family: 'manrope', sans-serif;
}

.cardText {
  color: #475467;
  font-size: 15px;
}


@media (min-width: 1201px) {
  .featureCard {
    max-width: 21rem;
  }
}

@media (max-width: 1200px) {
  .featureCard {
    max-width: 18.5rem;
  }

  .howItWorks div:nth-child(1) h1 {
    font-size: 40px;
  }
}

@media (max-width: 1100px) {
  .cardsContainer {
    gap: 1rem;
    padding: 4rem 2rem 0 2rem;
  }

  .featureCard {
    padding: 1.4rem 1.4rem 0.2rem 1.4rem;
    max-width: 17rem;
  }
}

@media (max-width: 1000px) {
  .cardsContainer {
    gap: 0.5rem;
    padding: 3rem 1rem 0 1rem;
    flex-wrap: wrap;
  }

  .featureCard {
    max-width: 18rem;
  }

  .howItWorks div:nth-child(1) h1 {
    font-size: 35px;
  }
}

@media (max-width: 900px) {}

@media (max-width: 800px) {
  .howItWorks div:nth-child(1) h1 {
    font-size: 28px;
  }

  .cardTitle {
    font-size: 16px;
  }

  .iconImage {
    width: 57px;
    height: 47px;
  }

  .cardText {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .howItWorks div:nth-child(1) h1 {
    font-size: 22px;
  }

  .cardTitle {
    font-size: 15px;
  }

  .cardText {
    font-size: 13px;
  }

  .cardsContainer {
    padding: 0rem 1rem 0 1rem;
  }

}

@media (max-width: 480px) {
  .howItWorks div:nth-child(1) {
    margin-top: 2.2rem;
  }

  .featureCard {
    padding: 1.4rem 1.4rem 1.2rem 1.4rem;
  }

  .howItWorks div:nth-child(1) h1 {
    font-size: 18px;
  }

  .cardTitle {
    font-size: 13px;
  }

  .cardText {
    font-size: 11px;
  }

  .iconImage {
    width: 50px;
    height: 39px;
  }

  .lineContainer {
    display: none;
  }



}