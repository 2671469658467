:root {
    --black: black;
    --blue: #0262DF;
}


/* DropDown Start */
.dropDown{
    position: relative;
}

.dropDownHead{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 1.9rem;
    width: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    user-select: none
}

.dropDownHead{
    font-size: 13px;
    font-weight: 600;
    color: #7A7A7A;
}

.dropDownBody{
    position: absolute;
    top: 40px;
    right: -5px;
    z-index: 1;
    user-select: none;
}

.filterdropDownBody{
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
}

.filterdropDownBodyInner{
    height: 7rem;
    width: 15rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
}

.dropDownBodyInner{
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
}

.dropDownBodyInner > div{
    height: 10.5rem;
    width: 13rem;
    overflow-y: auto;
}

.dropDownBodyInner > div::-webkit-scrollbar {
    width: 10px;
}

.dropDownBodyInner > div::-webkit-scrollbar-track {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.dropDownBodyInner > div::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.dropDownBodyInner > div::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d49 90%);
    border-radius: 7px;
}

.ddPointerCon{
    position: absolute;
    top: -5px;
    right: 15px;
    z-index: 1;
}

.dropDownPointer{
    box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
    background: white;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
}

.dropDownElem{
    padding: 0 7px;
    /* border: 1px solid; */
    /* border-left: 3px solid; */
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    border-color: transparent;
    transition-property: all;
    transition-duration: 0.3s;
}

.dropDownElem h5{
    cursor: pointer;
    user-select: none;
}

.activeDropElem{
    border-color: var(--dropdown-border) !important;
}

.activeDropElem h5{
    /* color: var(--dropdown-border); */
}

.dropDownElem:hover {
    border-color: var(--dropdown-border);
}

.dropDownElem:hover h5{
    /* color: var(--dropdown-border); */
}

.dropDownElem h5{
    font-weight: 600;
}

.dropDownLine{
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 5px 11px;
}
/* DropDown End */

.SelectionTitle{
    font-size: 15px;
    font-weight: 600;
    color: black;
    user-select: none;
}

.selectionMain{
    width: 100%;
}

.selectionCon{
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: 24px;
    padding: 0 !important;
}

.filterElem{
}

.iconCon{
    height: 10px;
    width: 10px;
    margin-right: 5px;
    transform: rotate(270deg);
}

.accordion {
    background-color: transparent !important;
    margin-bottom: 10px;
    box-shadow: none !important;
}

.accordionBody {
    /* border: 1px solid red; */
    padding: 0px 16px !important;
    padding-bottom: 10px !important;
    max-height: 8.5rem;
    overflow-y: auto;
}

.accordionBody::-webkit-scrollbar {
    width: 10px;
}

.accordionBody::-webkit-scrollbar-track {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.accordionBody::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.accordionBody::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d49 90%);
    border-radius: 7px;
}