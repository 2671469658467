.dropDown{
    position: relative;
}

.dropDownHead{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 1.9rem;
    width: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    user-select: none
}

.dropDownHead{
    font-size: 13px;
    font-weight: 600;
    color: #7A7A7A;
}

.dropDownBody{
    position: absolute;
    top: 40px;
    right: -5px;
    z-index: 1;
    user-select: none;
}

.filterdropDownBody{
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
}

.filterdropDownBodyInner{
    /* height: 7rem; */
    width: 14rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 11px 16px;
    z-index: 2;
}

.dropDownBodyInner{
    /* height: 7rem; */
    width: 9.5rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 10px 0;
    z-index: 2;
}

.ddPointerCon{
    position: absolute;
    top: -5px;
    right: 15px;
    z-index: 1;
}

.dropDownPointer{
    box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
    background: white;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
}

.dropDownElem{
    padding: 2px 10px;
    border-left: 3px solid;
    cursor: pointer;
    border-color: transparent;
    transition-property: all;
    transition-duration: 0.3s;
    position: relative;
}


.activeDropElem{
    border-color: var(--dropdown-border) !important;
}

.activeDropElem > h5{
    color: var(--dropdown-border);
}

.dropDownElem:hover {
    border-color: var(--dropdown-border);
}

.dropDownElem:hover > h5{
    color: var(--dropdown-border);
}

.dropDownElem > h5{
    font-weight: 600;
    font-size: 13px;
}

.dropDownLine{
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 5px 11px;
}

.dropDownElem:hover > .dropDownBody2{
    display: block;
}

.dropDownBody2{
    display: none;
    position: absolute;
    left: 9rem;
    top: -4px;
    z-index: 1;
    padding-left: 10px;
    user-select: none;
}