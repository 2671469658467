:root {
  --black: black;
  --blue: #0262df;
}

.popup {
  background-color: #00000057;
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.card {
  background: white;
  padding: 1.5rem 5rem 1.5rem 5rem;
  padding-top: 7rem;
  border-radius: 15px;
  width: fit-content;
  text-align: left;
  border: none;
  position: relative;
  width: 28rem;
}

.bgPopup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9rem;
  background: #0262df;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 15px 15px 0 0;
}

.curveCon {
  position: relative;
  bottom: -1px;
  height: 2.5rem;
  width: 100%;
  background: white;
  border-radius: 50% 50% 0 0;
}

.card form {
  position: relative;
  z-index: 2;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardCon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px 0;
  border-radius: 8px;
}

.cardCon h5 {
  margin: 0;
}

.cardCon button h5 {
  color: white;
  font-size: 15px;
}

.cardCon a {
  text-decoration: none;
}

.title {
  font-weight: 700;
  font-size: 18px;
  margin-top: 1rem;
  text-align: center;
}

.title2 {
  font-weight: 700;
  font-size: 22px;
  margin-top: 1rem;
  text-align: center;
}

.text1 {
  font-size: 16px;
  margin: 15px 0;
}

.text2 {
  font-size: 15px;
  text-align: center;
}

.text3 {
  font-size: 14px;
  color: var(--blue);
}

.input {
  border: 1px solid rgba(122, 122, 122, 0.5);
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: black;
  padding: 7px 15px;
  box-sizing: border-box;
  margin: 0.8em 0;
  outline: none;
}

.input::placeholder {
  color: grey;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.btn1,
.btn2 {
  background: none;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 7px 15px;
  cursor: pointer;
  width: 100%;
}

.btn1 h5,
.btn2 h5 {
  margin: 0;
  font-size: 14px !important;
}

.m1r {
  margin: 1rem 0 !important;
}

.selectionCon {
  max-height: 10.5rem;
  margin: 1rem 0;
  overflow-y: auto;
  width: 100%;
}

.selectionElem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectionCon::-webkit-scrollbar {
  width: 10px;
}

.selectionCon::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.015) -122%,
    #f3f3f3 95%
  );
  border-radius: 7px;
}

.selectionCon::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d23 90%
  );
  border-radius: 7px;
}

.selectionCon::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d49 90%
  );
  border-radius: 7px;
}

.searcCon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 10px 17px;
  margin-right: 2.5%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 0.7rem 0;
}

.searcCon h5 {
  margin: 0;
  line-height: 1rem;
  text-align: center;
}

.searcCon input {
  height: 100%;
  border: none;
  background: none;
  outline: none;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  width: 100%;
}

.searcCon input::placeholder {
  color: rgb(153, 153, 153);
}
