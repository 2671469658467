.navContainer {
    border: 1px solid transparent;
    height: 5.5rem;
    display: grid;
    grid-template-columns: 27% 46% 27%;
    position: relative;
    z-index: 100;
}

.navContainer div:nth-child(1) {
    display: flex;
    justify-content: end;
    align-items: center;
}

.logoImage {
    width: 200px;
    height: 35px;
    margin-bottom: 13px;
}

.navContainer nav:nth-child(2) {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
}

.navContainer div:nth-child(3) {
    display: flex;
    justify-content: start;
    align-items: center;
}

.logoLink {
    cursor: pointer;
    text-decoration: none;
}

.navMenu a {
    color: black;
    font-family: 'manrope', sans-serif;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.navLink:last-child {
    padding-right: 0;
}

.dashboardLink {
    cursor: pointer;
    text-decoration: none;
}

.dashboardIcon {
    color: #0262DF;
    font-size: 35px;
}

.authButtons {
    cursor: pointer;
    text-decoration: none;
}

.signInButton {
    background: none;
    border: 1px solid #0262DF;
    color: #0262DF;
    height: 38px;
    border-radius: 8px;
    font-weight: 600;
    font-family: manrope, sans-serif;
    font-size: 13px;
    padding: 0.3rem 2rem;
    text-decoration: none;
    cursor: pointer;
}

.tryFreeButton {
    height: 38px;
    border-radius: 8px;
    font-weight: 600;
    font-family: manrope, sans-serif;
    font-size: 13px;
    padding: 0.3rem 2rem;
    text-decoration: none;
    cursor: pointer;
    margin-left: 0.5rem;
    color: white;
    border-color: transparent;
    background: #0262DF;
    background: linear-gradient(90deg, #0262DF 0%, #004DB1 100%);
}

@media (max-width: 1100px) {
    .navContainer nav:nth-child(2) {
        gap: 1rem;
    }
}


@media (max-width: 1100px) {
    .tryFreeButton {
        padding: 0.3rem 1rem;
    }

    .signInButton {
        padding: 0.3rem 1.5rem;
    }
}