.css-1nvcaqg-MuiFormControl-root-MuiTextField-root {
  padding: 0 !important;
}



.css-j204z7-MuiFormControlLabel-root {
  margin: 0 !important;
  margin-right: -5px !important;
}
.css-1jaw3da {
  margin: 0 !important;
  margin-right: -5px !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 4px;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0 0 8px 0 !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 32px !important;
}

#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 100 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
  border-top-color: #00c8b1;
  border-left-color: #00c8b1;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.css-1iji0d4 {
  background: none !important;
}

img,
button {
  user-select: none;
}

.noSelect {
  user-select: none;
}
