:root {
    --black: black;
    --blue: #0262DF;
}

.gradientBox {
    background-image: linear-gradient(to top, #0262df 0%, #04befe 100%) !important;
}

.gradientBtnBlue {
    background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important;
}

.gradientBtnWhite {
    background: linear-gradient(to top, #d4d4d4 0%, #efefef 100%) !important;
}

.gradientTxtBlack {
    background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, rgb(72 72 72 / 89%) 0.89%, rgb(56 56 56) 17.23%, rgb(0 0 0 / 65%) 42.04%, rgb(0 0 0 / 79%) 55.12%, #333333 71.54%, #404040 100%);
}

.section {
    margin-top: 4rem;
}

.flexContainer {
    display: flex;
    justify-content: center;
}

.flexItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.heading {
    font-family: sans-serif, manrope;
    color: black;
    margin: 0;
    margin-bottom: 3rem;
}

.card {
    background: linear-gradient(90deg, #0262DF 0%, #004DB1 100%);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: fit-content;
    padding: 3rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 3rem;
}

.cardLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid black; */
}

.cardLeft h2 {
    color: white;
    margin: 0;
    font-weight: 600;
    margin-bottom: 20px;
}

.cardLeft button {
    background: #FFFFFF;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 13px;
}

.cardLeft button h5 {
    color: #0262DF;
    margin: 0;
    font-weight: 600;
}

.cardRight {
    /* border: 1px solid black; */
}

.cardRight h5 {
    color: white;
    margin: 0;
    font-weight: 400;
}

.list {
    margin-top: 12px;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 7px;
}

.listItem img {
    height: 15px;
}

@media (max-width: 1920px) {
    .heading {
        font-size: 45px;
    }

    .cardLeft h2 {
        font-size: 28px;
    }
}

@media (max-width: 1200px) {
    .heading {
        font-size: 40px;
    }

    .cardLeft h2 {
        font-size: 27px;
    }

    .text {
        font-size: 15px;
    }
}

@media (max-width: 1000px) {
    .heading {
        font-size: 35px;
    }

    .cardLeft h2 {
        font-size: 25px;
    }

    .text {
        font-size: 15px;
    }
}

@media (max-width: 800px) {
    .heading {
        font-size: 28px;
    }

    .cardLeft h2 {
        font-size: 22px;
    }

    .text {
        margin: 0;
    }
}

@media (max-width: 600px) {
    .card {
        flex-direction: column;
        padding: 2rem 3rem;
        gap: 1.3rem;
    }

    .cardLeft button {
        border-radius: 7px;
        padding: 8px;
    }

    .cardLeft button h5 {
        font-size: 13px;
    }

    .heading {
        font-size: 22px;
    }

    .cardLeft h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .text {
        font-size: 13px;
    }

    .text br {
        display: none;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 18px;
        text-align: center;
    }

    .text {
        font-size: 13px;
        text-align: center;
    }

    .text br {
        display: none;
    }
}