@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700");

.banner {
  background-color: white;
  padding: 0 5.5%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* box-shadow: 0px 4px 12px rgb(0 0 0 / 7%); */
}

.banner > div {
  /* border: 1px solid black; */
}

.banner h5,
.hero3 h5,
.hero2 h5,
.hero1 h5,
h3 {
  margin: 0;
}

.mr10 {
  margin-right: 10px;
}

.mainRightBg {
  background: #f8f8f8;
  padding-top: 40px;
  border-radius: 30px 0 0 0;
  overflow: hidden;
  padding-bottom: 1rem;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexAlign {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flexAlign7 {
  display: flex;
  align-items: center;
  gap: 7px;
}

.flexAlign20 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.flexAlign10 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flexAlignStart10 {
  display: flex;
  align-items: start;
  gap: 10px;
  line-height: 1rem;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fw600 {
  font-weight: 600;
}

.bannerRight {
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.mainNotifications {
  position: relative;
}

.bellCon {
  padding: 6px 7px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

.bellCon:hover {
  background: rgb(0 0 0 / 5%);
}

.bellCon img {
  height: 1.3rem;
}

.bellCon span {
  position: absolute;
  top: 0;
  right: 0;
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff0004;
  border-radius: 50%;
}

.bellCon h5 {
  font-size: 7px;
  color: white;
  margin-left: 1px;
}
.notifications {
  position: absolute;
  top: 37px;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 6px 25px rgb(0 0 0 / 8%);
  border-radius: 10px;
  height: 18rem;
  width: 16rem;
  z-index: 5;
}

.notifyBanner {
  padding: 7px 15px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 3%);
  border-radius: 10px 10px 0px 0px;
}

.notifyBanner h5 {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: black;
}

.notifyBody {
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyBox {
}

.emptyBox img {
  height: 5rem;
}

.emptyBox h5 {
  color: rgb(175 175 175);
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.hero2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 5%;
  margin-top: 0;
}

.hero2 h5 {
  font-size: 13px;
  font-weight: 600;
}

.searcCon {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 4px 12px;
  margin-right: 2.5%;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.searcCon h5 {
  margin: 0;
  line-height: 1rem;
  text-align: center;
}

.searcCon input {
  height: 100%;
  border: none;
  background: none;
  outline: none;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  width: 9rem;
}

.searcCon input::placeholder {
  color: rgb(153, 153, 153);
}

.searcCon img {
  height: 0.7rem;
}

.searcCon span {
  color: #0262df;
  font-size: 12px;
}

.addBtn {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  padding: 5px 15px;
  color: #848484;
  cursor: pointer;
}

.addBtn h5 {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.hero3 {
  margin-left: 5%;
}

.swiperElem {
  width: fit-content !important;
  /* margin-right: 15px !important; */
}

.card {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  /* height: 75vh; */
  width: 22rem;
  padding: 1rem;
  flex: 0 0 22rem;
}

.cardInner {
  height: 80vh;
  /* padding-right: 5px; */
  overflow-y: auto;
}

.cardInner::-webkit-scrollbar {
  width: 10px;
}

.cardInner::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.015) -122%,
    #f3f3f3 95%
  );
  border-radius: 7px;
}

.cardInner::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d23 90%
  );
  border-radius: 7px;
}

.cardInner::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.738) -122%,
    #2d2d2d49 90%
  );
  border-radius: 7px;
}

.card > h5 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.cardBox {
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  width: 100%;
  min-height: 10rem;
  padding: 0.9rem 0.8rem;
  margin-bottom: 5px;
}

.icon {
  height: 25px;
}

.titleMini {
  font-size: 14px;
  font-weight: 700;
}

.titleMini2 {
  font-size: 16px;
  font-weight: 700;
}

.textMiniLight {
  font-size: 10px;
  color: #9e9e9e;
}

.line {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 12px 0;
}

.textLight {
  font-size: 13px;
  color: #7a7a7a;
  font-weight: 600;
}

.mb10 {
  margin-bottom: 10px;
}

.rateBtn {
  background: none;
  border: none;
  border-radius: 7px;
  padding: 6px 10px;
  cursor: pointer;
}

.rateBtn h5 {
  color: white;
  font-size: 10px;
}

.scoreText {
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

/* Dropdown start */
.dropDown {
  position: relative;
}

.dropDownHead {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-size: 13px;
    font-weight: 600;
    color: #7a7a7a;
}

.dropDownHead2 {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 2rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
  color: #7a7a7a;
}

.dropDownBody {
  position: absolute;
  top: 28px;
  right: -9px;
  z-index: 1;
  user-select: none;
}

.filterdropDownBody {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
}

.filterdropDownBodyInner {
  height: 7rem;
  width: 15rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 10px 0;
  z-index: 2;
}

.dropDownBodyInner {
  /* height: 7rem; */
  width: 11rem;
  background: #ffffff;
  box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 9%);
  border-radius: 5px;
  padding: 10px 0;
  z-index: 2;
}

.ddPointerCon {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1;
}

.dropDownPointer {
  box-shadow: -2px -2px 3px rgb(0 0 0 / 6%);
  background: white;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
}

.dropDownElem {
  padding: 2px 10px;
  border-left: 3px solid;
  cursor: pointer;
  border-color: transparent;
  transition-property: all;
  transition-duration: 0.3s;
}

.activeDropElem {
  border-color: var(--dropdown-border) !important;
}

.activeDropElem h5 {
  color: var(--dropdown-border);
}

.dropDownElem:hover {
  border-color: var(--dropdown-border);
}

.dropDownElem:hover h5 {
  color: var(--dropdown-border);
}

.dropDownElem h5 {
  font-weight: 600;
  font-size: 12px;
  color: black;
}

.dropDownLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: 5px 11px;
}

.loadingPopup {
  background-color: #ffffffa2;
  backdrop-filter: blur(1px);
  /* margin-left: 265px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.loadingPopup h5 {
  font-size: 15px;
  font-weight: 600;
}

.dropDownMiniTitle{
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px !important;
    margin-left: 12px !important;
}