/* Footer.module.css */
.footer {
    background: linear-gradient(90deg, #0262DF 0.94%, #013579 100.31%);
    min-height: 20rem;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 12rem 0 2rem 0;
    position: relative;
    margin-top: 15rem;
}

.card {
    width: 55%;
    min-height: 10rem;
}

.logo {
    height: 2rem;
    margin-bottom: 2rem;
}

.list {
    display: flex;
    align-items: start;
}

.listCon {
    width: 10rem;
}

.listCon .text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.title {
    font-size: 15px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    color: white;
    margin: 0;
    margin-bottom: 20px;
}

.text {
    font-size: 13px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    color: white;
    margin: 0;
    margin-bottom: 10px;
}

.miniText {
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    color: white;
    margin: 0;
}

.flexAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.partnersLogo {
    height: 3.5rem;
}

.center {
    text-align: center;
}

.banner {
    background: #013579;
    border-radius: 15px;
    width: 55%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 3rem;
    box-sizing: border-box;
    padding: 5.5rem 5rem;
}

.bannerInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.bannerInner>div {
    position: relative;
    z-index: 5;
}

.bannerTitle {
    color: white;
    font-family: 'Manrope', sans-serif;
    font-size: 35px;
    margin: 0;
    margin-bottom: 2rem;
}

.flexAlign2 {
    display: flex;
    align-items: center;
    gap: 20px;
}

.flexAlign3 {
    display: flex;
    align-items: center;
    gap: 7px;
}

.blueBtn {
    background: #0262DF;
    outline: none;
    border: none;
    padding: 10px 2rem;
    border-radius: 10px;
    margin-bottom: 10px;
    z-index: 2;
    cursor: pointer;
}

.blueBtn h5 {
    color: white;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.line {
    width: 100%;
    border-color: #ffffff42;
    margin: 2rem 0 1.5rem 0;
}

.m0 {
    margin: 0 !important;
}

.socialIcon {
    border-radius: 7px;
    background: white;
    height: 1.7rem;
    width: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialIcon svg {
    height: 1.2rem;
}

.footerWave {
    position: absolute;
    top: 75%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 80%;
}

@media (max-width: 1550px) {

    .card,
    .banner {
        width: 70%;
    }

    .banner {
        padding: 4rem 3rem;
    }
}

@media (max-width: 1100px) {

    .card,
    .banner {
        width: 80%;
    }

    .bannerTitle {
        font-size: 30px;
    }

    .listCon {
        width: 7rem;
    }
}

@media (max-width: 1000px) {
    .banner {
        padding: 2rem 3rem;
    }
}

@media (max-width: 900px) {
    .bannerTitle {
        font-size: 25px;
    }

    .card,
    .banner {
        width: 90%;
    }
}

@media (max-width: 700px) {
    .flexBetween {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
    }

    .title,
    .text {
        text-align: center;
    }

    .bannerInner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .bannerTitle {
        margin-bottom: 1rem;
        text-align: center;
        font-size: 20px;
    }
}

@media (max-width: 600px) {}

@media (max-width: 480px) {
    .banner {
        padding: 2rem 1rem;
    }

    .list {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1rem;
    }
}