:root {
    --black: black;
    --blue: #0262DF;
}

.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: white;
    padding: 1.7rem 2rem 2rem 2rem;
    border-radius: 8px;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0px 4px 12px #0362e038; */
    max-width: 320px;
    /* min-width: 200px; */
    width: 300px;
    /* height: max-content; */
    text-align: left;
    border: none;
    /* border-color: #0262DF; */
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.crownImg{
    margin-top: 0.5rem;
    margin-bottom: .7rem;
    background: #ed3a2324;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    border-radius: 50%;
}

.crownImg svg{
    width: 1.8rem;
}

.header h5 {
    margin: 0;
    margin-bottom: 25px;
    color: #676767;
    text-align: center;
}

.planName {
    color: var(--black);
    font-weight: 550;
    margin: 0px;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    text-align: center;
}

.price {
    font-size: 42px;
    margin-top: -0.2rem;
    color: var(--black);
    border-bottom: 2px solid #0262DF;
    margin-top: 1.2rem;
    padding-bottom: 0.8rem;
    margin-bottom: 1rem;
}

.colorWhite {
    color: white;
}

.priceSuffix {
    font-size: 20px;
    font-weight: 400;
    margin-left: 7px;
}

.featureList {
    padding: 0;
    min-height: 9rem;
}

.featureList li {
    margin-bottom: 5px;
}

.featureIncluded {
    color: #0262DF;
    padding: 0.1rem;
}

.card1_p {
    margin: 0;
    margin-left: -1.5rem;
    font-size: 15px;
    font-weight: 500;
    /* line-height: 1rem; */
    color: var(--black);
}

.premiumBtn {
    border: none !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    background: #0262DF;
    padding: 8px !important;
    border-radius: 5px !important;
    margin-top: 10px !important;
    cursor: pointer !important;
    width: 100% !important;
    font-family: "Manrope", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    line-height: normal !important;
    font-weight: 500 !important;
    transition-property: all !important;
    transition-duration: 0.3s !important;
}

.premiumBtn:hover {
    /* background-color: #013d8b !important; */
    font-weight: 700 !important;
}

.premiumBtn>span {
    background-image: none !important;
    font-family: "Manrope", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    position: relative !important;
    padding: initial !important;
    display: block !important;
    height: auto !important;
    line-height: normal !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 500 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-radius: 0 !important;
}

.premiumBtn:hover>span {
    font-weight: 700 !important;
}

.premiumBtn>h5 {
    font-size: 12px;
    margin: 0;
}

.premiumBtn img {
    height: 18px;
}

.close {
    position: absolute;
    right: 23px;
    top: 16px;
    cursor: pointer;
}

.close img {
    height: 0.5rem;
    width: 0.5rem;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.pricingBtns {
    max-width: 310px;
    width: fit-content;
    padding: 0.2rem;
    border-radius: 25px;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

}

.pricingBtns button {
    /* background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important; */
}

.activeButton,
.inactiveButton {
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    padding: 0.3rem 1rem;
    border: 1px solid transparent;
    transition: background-color 0.3s, color 0.3s;
    /* Add transition for background-color and color */
}

.activeButton {
    /* border: none; */
    background: #0262DF;
    /* background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important; */
    color: white;
}

.inactiveButton {
    background: transparent;
    color: black;
}

.saveImage {
    height: 2.5rem;
    position: absolute;
    right: -40px;
    top: -1px;
}