.hero {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: none;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    z-index: 10000;
}

.header {
    height: max-content;
    width: 100%;
    position: relative;
}

.bg {
    height: 12rem;
    width: 100%;
}

.icon {
    margin-bottom: 10px;
    position: absolute;
    top: 5rem;
    z-index: 1;
    height: 2rem;
}

.infoIcon {
    margin-top: 3rem;
    margin-bottom: 2rem;
    height: 3.5rem;
    width: 3.5rem;
}

.hero h3 {
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    text-align: center;
}

.hero h5 {
    font-size: 17px;
    color: #676767;
    text-align: center;
    margin-top: 1rem;
    font-weight: 400;
    /* line-height: 1.7rem; */
}

.para {
    font-family: "Manrope", sans-serif;
    color: #676767;
    font-size: 14px;
    text-align: center;
    margin-top: 4rem;
}

.hero button {
    background-color: #0262DF;
    padding: 4px 35px;
    border: none;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 7px;
    margin-top: 10px;
}

.hero button>h5 {
    color: white;
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 16px;
}

.goBackText {
    font-family: "Manrope", sans-serif;
    color: black;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 0.7rem;
    cursor: pointer;
}

@media (max-width: 480px) {
    .hero {
        display: flex;
    }
}

@media (max-width: 400px) {
    .bg {
        height: 10rem;
        width: 100%;
    }

    .icon {
        height: 1.5rem;
    }

    .infoIcon {
        margin-top: 1.5rem;
        height: 3rem;
        width: 3rem;
    }

    .hero h3,
    .hero h5 {
        font-size: 15px;
    }

    .hero button>h5 {
        font-size: 14px;
    }
}