.backgroundImage {
    background-color: #FBFBFD;
}

.navContainer {
    border: 1px solid transparent;
    height: 4.5rem;
    position: relative;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.navContainer div:nth-child(1) {
    display: flex;
    justify-content: end;
    align-items: center;
}

.logoImage {
    width: 200px;
    height: 35px;
    margin-bottom: 7px;
}

.minilogoImage {
    width: 100%;
    height: 35px;
    margin-bottom: 7px;
}

.navContainer nav:nth-child(2) {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
}

.navContainer div:nth-child(3) {
    display: flex;
    justify-content: start;
    align-items: center;
}

.logoLink {
    cursor: pointer;
    text-decoration: none;
}

.navMenu a {
    color: black;
    font-family: 'manrope', sans-serif;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.navLink:last-child {
    padding-right: 0;
}

.flexAlign img {
    width: 16px;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 9px;
}

.goBackTxt {
    color: #0262DF;
    cursor: pointer;
}

p {
    margin: 0;
}

.hero {
    min-height: 80vh;
    padding-top: 6rem;
    display: flex;
    justify-content: center;
    gap: 4rem;
}

.heroLeft {
    height: fit-content;
    width: 25rem;
}

.list {
    margin-top: 2rem;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.listItem img {
    height: 15px;
}

.subheading {
    font-size: 35px;
    font-family: sans-serif, manrope;
    color: black;
    text-align: start;
    margin: 0;
    line-height: 2.7rem;
    margin-bottom: 1rem;
}

.text {
    text-align: start;
    color: #475467;
    font-size: 15px;
}


.form_con {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 25rem;
    height: fit-content;
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: .7rem;
}

.form_con input {
    border: 1px solid #DEDEDE;
    border-radius: 15px;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    background: none;
    border-radius: 10px;
    height: 2.4rem;
    outline: none;
    padding: 0 20px;
    font-size: 12px;

}

.form_con input::placeholder {
    color: #989898;
}

.form_con textarea {
    border: 1px solid #DEDEDE;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    outline: none;
    padding: 13px 20px;
    font-size: 12px;
}

.form_con textarea::placeholder {
    color: #989898;
}

.form_con_row {
    display: grid;
    grid-template-columns: 50% 50%;
}

.form_con_row input:nth-child(1) {
    margin-right: 10px;
}

.form_con_row input:nth-child(2) {
    margin-left: 10px;
}

.form_con button {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    width: 100%;
    background-color: #0262DF;
    color: white;
    height: 2.4rem;
    border-radius: 7px;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
}

.form_con button h5 {
    font-size: 13px;
}

.btnCon {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.m0 {
    margin: 0;
}

.msgCon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 10px;
}

.checkmark {
    height: 1.6rem;
    width: 1.6rem;
}

.msgCon h5 {
    text-align: center;
    margin: 0;
}

.flexAlign2 {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.companyType {
    padding-left: 7px;
}

.companyType label h5 {
    font-size: 12px;
    color: #989898;
    margin: 0 0 2px 0;
}

.RadioGroup {
    height: fit-content;
    margin-left: 0;
}

.radioCon {
    height: fit-content;
}

.radioCon svg {
    width: 15px;
    height: 15px;
}

.radioCon span {
    font-size: 12px;
    color: #989898;
}

.blue {
    color: #0262DF;
}

@media (min-width: 320px) and (max-width: 480px) {
    .form_con {
        width: 80%;
    }

    .form_con_row {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        margin: 0;
    }

    .form_con_row input:nth-child(1) {
        margin-right: 0px;
    }

    .form_con_row input:nth-child(2) {
        margin-left: 0;
    }

    .btnCon {
        margin-bottom: 2rem;
    }
}

@media (max-width: 1200px) {
    .hero {
        padding-top: 4rem;
    }
}

@media (max-width: 1200px) {
    .subheading {
        font-size: 35px;
    }

    .text {
        font-size: 15px;
    }
}

@media (max-width: 1000px) {
    .subheading {
        font-size: 30px;
        line-height: 2rem;
    }

    .text {
        font-size: 15px;
    }

    .hero {
        gap: 2rem;
    }

    .form_con,
    .heroLeft {
        width: 20rem;
    }
}

@media (max-width: 800px) {
    .hero {
        flex-direction: column;
        align-items: center;
    }

    .subheading {
        font-size: 24px;
        text-align: center;
    }

    .text {
        margin: 0;
        font-size: 13px;
        text-align: center;
    }

    .list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .listItem {
        width: 11rem;
    }
}

@media (max-width: 600px) {
    .subheading {
        font-size: 22px;
        line-height: normal;
    }

    .form_con {
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .subheading {
        font-size: 20px;
        text-align: center;
        /* line-height: 1.5rem; */
    }

    .text {
        font-size: 12px;
        text-align: center;
    }

    .text br {
        display: none;
    }

    .form_con {
        width: 75%;
    }
}